import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WlRateRacePagingViewModel } from '../../models/wl-rate-race-paging-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatDateTime, GaPagedResult, GaPagingForm, GaTableData } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlRateRaceListPagingForm, WlRateRaceStatesExtensions, WlRateRaceTournamentListModel } from 'src/app/modules/autogen/BattlePass';
import { GaSearchManagementStateService } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-rate-race-list',
    templateUrl: './wl-rate-race-list.component.html',
    styleUrls: ['./wl-rate-race-list.component.scss'],
    providers: [GaSearchManagementStateService]
})
export class WlRateRaceListComponent implements OnInit {
    protected result: GaPagedResult<WlRateRaceTournamentListModel>;
    protected tableData: GaTableData<WlRateRaceTournamentListModel>;
    protected pagingViewModel = new WlRateRacePagingViewModel();

    constructor(private readonly _service: BattlePassRateRaceService,
                private readonly _router: Router,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlRateRaceTournamentListModel>>
    ) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public add(): void {
        this._router.navigate(['/menu/raterace/create']);
    }

    public pageChanged(pagingEvent: GaPagingForm): void {
        this._searchService.nextSearch(pagingEvent);
    }

    public pageFiltered(pagingEvent: GaPagingForm = null): void {
        this._searchService.nextSearch(pagingEvent, true);
    }

    private init(): void {
        this._searchService.init(u => this._service.list(u as WlRateRaceListPagingForm), this.pagingViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.result = res;
            this.mapToTable(res.results);
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(models: WlRateRaceTournamentListModel[]): void {
        const data = new GaTableData<WlRateRaceTournamentListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['/menu/raterace/update', elem.id],
                {title: 'Id', widthSize: 80}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 250})
            .addSimpleColumn((elem) => elem.battlePassSeasonId ?? '-', {title: 'Сезон БП', widthSize: 100})
            .addSimpleColumn((elem) => WlRateRaceStatesExtensions.format(elem.state), {title: 'Текущий статус', widthSize: 200})
            .addSimpleColumn((elem) => elem.prizePlacesCount, {title: 'Призовых мест', widthSize: 150})
            .addSimpleColumn((elem) => elem.entityActors.creatorAccountLogin, {title: 'Создано', widthSize: 150})
            .addSimpleColumn((elem) => elem.entityActors.editorAccountLogin, {title: 'Изменено', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.createdDate), {title: 'Дата создания', widthSize: 130})
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.modifiedDate), {title: 'Дата изменения', widthSize: 130})
            .setData(models);
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
