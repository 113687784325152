import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm, PagingViewModel } from '@koddington/ga-common';
import { BattlePassGameLootBoxesService, WlGameLootBoxListModel, WlGameLootBoxSearchForm } from 'src/app/modules/autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-game-loot-box-list',
    templateUrl: './wl-battle-pass-game-loot-box-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassGameLootBoxListComponent implements OnInit {
    public result: GaPagedResult<WlGameLootBoxListModel>;
    public tableData: GaTableData<WlGameLootBoxListModel>;
    public viewSearchModel: PagingViewModel = new PagingViewModel();

    constructor(private readonly _battlePassLootBoxesService: BattlePassGameLootBoxesService,
                private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlGameLootBoxListModel>>,
                private readonly _router: Router) {
    }

    ngOnInit(): void {
        this.init();
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/lootBox/edit']);
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._battlePassLootBoxesService.lootBoxList(form as WlGameLootBoxSearchForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlGameLootBoxListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                {title: 'Код', widthSize: 100}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 600})
            .addSimpleColumn((elem) => elem.description, {title: 'Описание', widthSize: 690})
            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
