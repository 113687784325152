import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IAutocompleteEntityTemplateStrategy } from '../../strict-autocomplete/contracts/autocomplete-strategy';
import { StrictFormControl } from '@koddington/ga-common';
import { StrictScrollAutocompleteViewModel } from '../../strict-scroll-autocomplete/models/strict-scroll-autocomplete-view-model';
import { IAutocompleteItem } from '../../strict-autocomplete/contracts/app-autocomplete-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined } from '@koddington/ga-common';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-strict-scroll-multi-select-autocomplete',
    templateUrl: './strict-scroll-multi-select-autocomplete.html',
    styleUrls: ['./strict-scroll-multi-select-autocomplete.scss'],
})
export class StrictScrollMultiSelectAutocompleteComponent<T> implements OnInit, OnDestroy, AfterContentInit {
    @ViewChild('scrollable') scrollable: ElementRef;

    @Input()
    public isSearch = false;
    @Input()
    public label: string | undefined;
    @Input()
    public placeholder = '';
    @Input()
    public multiSelectControl: StrictFormControl<T[]>;
    @Input()
    public strategy: IAutocompleteEntityTemplateStrategy<T, StrictScrollAutocompleteViewModel>;
    @Input()
    public minElements: number;
    @Input()
    public maxElements: number;
    @Input()
    public isLoadAll = false;
    @Input()
    public instantLoad = false;

    @Output()
    public userFinalSelected = new EventEmitter<T>();
    @Output()
    public userSelect = new EventEmitter<T>();
    @Output()
    public readonly loadAllData = new EventEmitter<void>();
    @Output()
    public readonly valueChange = new EventEmitter<void>();

    public control: StrictFormControl<T> = new StrictFormControl<T>();
    public viewModel: StrictScrollAutocompleteViewModel;
    public currentItem: IAutocompleteItem<T>;
    public currentItemsSource: IAutocompleteItem<T>[] = [];
    public selectedItems: IAutocompleteItem<T>[] = [];
    public emmitClearToControl: Subject<void>;

    public scrollingCount = 0;
    public isActive = false;
    public isFocused = false;
    private currentFocus = 0;
    private readonly scrollBeginPosition: number = 0;
    public isEnabled = true;
    protected readonly clearSource$: Subject<void> = new Subject<void>();
    protected readonly isEnableInput$: Subject<boolean> = new Subject<boolean>();

    private readonly KeyDownCode: string = 'ArrowDown';
    private readonly KeyUpCode: string = 'ArrowUp';
    private readonly KeyEnterCode: string = 'Enter';

    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        map((event) => !this._elementRef.nativeElement.contains(event.target)),
        filter((outside: boolean) => outside)
    );

    private readonly termSource$: Subject<string> = new Subject<string>();

    constructor(protected _elementRef: ElementRef) {}

    public ngOnInit(): void {
        if (isNullOrUndefined(this.strategy)) {
            throw new Error('Strategy for dropdown cannot be undefined');
        }

        if (isNullOrUndefined(this.multiSelectControl)) {
            throw new Error('multiSelectControl for strict scroll multi select cannot be undefined');
        }
    }

    public ngOnDestroy(): void {
        this.control = new StrictFormControl<T>();
    }

    public ngAfterContentInit() {
        this.initItems();
        this.subcriptionLoad();
        this.subscriptionMultiChangeControl();
        this.subscriptionToChangeGlobalFocus();
        this.subscriptionChangeTerm();
        this.subscriptionIsEnableInput();

        if (this.instantLoad) {
            this.strategy.emitUpdateEvent();
        }
    }

    private initItems(): void {
        this.viewModel = new StrictScrollAutocompleteViewModel();
        this.strategy.bindControlModel(this.viewModel);

        if (this.multiSelectControl.hasStrictValue) {
            this.selectedItems = this.multiSelectControl.strictValue.map((v) => this.strategy.convert(v));
        }

        this.currentItem = { entity: null, key: '', title: '' };
        this.control.setValue(this.currentItem.entity);
    }

    private subcriptionLoad(): void {
        this.strategy
            .getSource()
            .pipe(
                map((items) => items.map((u) => this.strategy.convert(u))),
                untilDestroyed(this)
            )
            .subscribe((result) => {
                this.currentItemsSource = [...this.currentItemsSource, ...result];
                this.scrollingCount = result.length;
                this.viewModel.isEndOfList.strictValue = false;
            }, console.log);
    }

    private subscriptionMultiChangeControl(): void {
        this.multiSelectControl.strictValueSource.pipe(untilDestroyed(this)).subscribe((u) => {
            this.selectedItems = this.multiSelectControl.strictValue.map((v) => this.strategy.convert(v));
        });
    }

    private subscriptionToChangeGlobalFocus(): void {
        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe(() => (this.isActive = false));
    }

    private subscriptionChangeTerm(): void {
        this.termSource$.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe((u) => {
            this.clearResult();
            this.currentItem = { entity: null, key: '', title: u };
            this.viewModel.term.strictValue = u;
            this.strategy.emitUpdateEvent();
        });
    }

    private subscriptionIsEnableInput(): void {
        this.isEnableInput$.pipe(untilDestroyed(this)).subscribe((v) => {
            this.isEnabled = v;
        });
    }

    public onInputChange(event: any): void {
        const term: string = event.target.value;

        if (isNullOrUndefined(term) || term.length === 0) {
            this.clearResult();
            this.resetValue();
        }
        this.isActive = true;
        this.termSource$.next(term);
    }

    public resetValue(): void {
        this.dropOffset();
        this.currentFocus = 0;
    }

    private dropOffset(): void {
        this.viewModel.offset.strictValue = 0;
        this.viewModel.isEndOfList.strictValue = false;
    }

    // INFO: This is event going not only scrolling but also onclick buttons and keyboard
    public onScroll(event: any): void {
        if (this.isEndScroll(event)) {
            this.nextLoad();
            event.target.scrollTop = event.target.scrollHeight - event.target.offsetHeight;
        }
    }

    private nextLoad(): void {
        this.viewModel.isEndOfList.strictValue = true;
        if (this.scrollingCount === 0 && this.currentItemsSource.length > 0) {
            return;
        }
        this.viewModel.offset.strictValue += this.viewModel.count.strictValue;
        this.strategy.emitUpdateEvent();
    }

    private isEndScroll(event: any): boolean {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1 && !this.viewModel.isEndOfList.strictValue && !this.isZeroState(event)) return true;
        return false;
    }

    private isZeroState(event: any): boolean {
        if (event.target.offsetHeight === 0 && event.target.scrollTop === 0 && event.target.scrollHeight === 0) {
            return true;
        }
        return false;
    }

    protected onClick(item: IAutocompleteItem<T>): void {
        if (this.control.disabled) {
            return;
        }
        this.isActive = false;

        if (isNullOrUndefined(item)) {
            this.userFinalSelected.emit();
            return;
        }
        this.setMultiValueAndClearInput(item);
    }

    private setMultiValueAndClearInput(item: IAutocompleteItem<T>): void {
        this.addItem(item);
        this.currentItem = null;
        this.updateViewModelTerm(null);
        this.clearResult();
        this.resetValue();
        this.userSelect.emit();
        this.strategy.emitUpdateEvent();
    }

    private addItem(item: IAutocompleteItem<T>): void {
        if (this.canAddItemByMaxElements() && !this.isExistItem(item.key)) {
            this.selectedItems.push(item);
            this.AddItemInMultiControler();
        }
    }

    private AddItemInMultiControler(): void {
        if (!isNullOrUndefined(this.selectedItems) || this.selectedItems?.length > 0) {
            this.updateStrictMultiControl();
        }
    }

    private canAddItemByMaxElements(): boolean {
        if (isNullOrUndefined(this.maxElements)) {
            return true;
        }

        if (this.selectedItems.length >= this.maxElements) {
            this.disableControl();
            return false;
        }
        this.enableControl();
        return true;
    }

    private disableControl(): void {
        this.control.disable({ emitEvent: false });
        this.isEnableInput$.next(false);
    }

    private enableControl(): void {
        if (this.control.disabled) {
            this.control.enable({ emitEvent: false });
            this.isEnableInput$.next(true);
        }
    }
    public removeItem(index: number): void {
        this.selectedItems.splice(index, 1);
        this.updateStrictMultiControl();
        if (this.selectedItems.length < this.maxElements) {
            this.enableControl();
        }
        this.userSelect.emit();
        this.emmitUserAction();
    }

    private updateStrictMultiControl(): void {
        this.multiSelectControl.strictValue = [...this.selectedItems.map((v) => v.entity)];
    }

    public isExistItem(key: string): boolean {
        return this.selectedItems.find((v) => v.key === key) !== undefined;
    }

    public onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case this.KeyDownCode:
                this.moveSelectionDown();
                break;
            case this.KeyUpCode:
                this.moveSelectionUp();
                break;
            case this.KeyEnterCode:
                if (this.isActive) {
                    const selectedItem = this.currentItemsSource[this.currentFocus];
                    this.onClick(selectedItem);
                } else {
                    this.userSelect.emit();
                    this.userFinalSelected.emit();
                }
                break;
            default:
                break;
        }
    }

    private moveSelectionUp() {
        if (this.currentFocus <= 0) {
            this.currentFocus = this.currentItemsSource.length - 1;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                this.setScrollYPosition(item.offsetTop);
            }
        } else {
            this.currentFocus--;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                if (this.isNextScrollByPosition(item.offsetTop)) {
                    this.setScrollYPosition(item.offsetTop);
                    return;
                }
                this.setScrollYPosition(this.scrollBeginPosition);
            }
        }
    }

    private moveSelectionDown() {
        if (this.currentFocus >= this.currentItemsSource.length - 1) {
            this.currentFocus = 0;
            this.setScrollYPosition(this.scrollBeginPosition);
        } else {
            this.currentFocus++;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                const bottomYPosition = item.offsetHeight + item.offsetTop;
                if (this.isNextScrollByPosition(bottomYPosition)) {
                    this.setScrollYPosition(item.offsetTop);
                }
            }
        }
    }

    private isNextScrollByPosition(coordinateY: number): boolean {
        return coordinateY > this.scrollable.nativeElement.offsetHeight;
    }

    private isNotEmptyDropDown(): boolean {
        return this.scrollable.nativeElement?.children.length > 0;
    }

    private setScrollYPosition(coordinateY: number): void {
        this.scrollable.nativeElement.scrollTop = coordinateY;
    }

    private getFocusedDIVElement(): HTMLDivElement {
        const childs = Array.from(this.scrollable.nativeElement?.children) as HTMLDivElement[];
        return childs[this.currentFocus];
    }

    public onFocus(event: any): void {
        if (this.currentItemsSource.length > 0) {
            this.isActive = true;
        }
    }

    public trackFocus(event: FocusEvent | null = null): void {
        if (isNullOrUndefined(event)) {
            this.isFocused = false;
            return;
        }
        this.isFocused = true;
    }

    protected clearResult() {
        this.currentItemsSource.length = 0;
        this.viewModel.offset.strictValue = 0;
    }

    public isShowElements(): boolean {
        return this.selectedItems?.length > 0;
    }

    private emmitUserAction(): void {}

    public loadAll(): void {
        this.isActive = false;
        this.loadAllData.emit();
    }

    private updateViewModelTerm(title: string, sendUpdateEvent: boolean = true): void {
        this.viewModel.term.strictValue = title;
        if (sendUpdateEvent)
            this.termSource$.next(title);
    }
}
