import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm, GaTableData, PagingViewModel } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlRateRaceLootBoxListModel, WlRateRaceLootBoxPagingForm } from 'src/app/modules/autogen/BattlePass';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-wl-rate-lootbox-list',
    templateUrl: './wl-rate-race-lootbox-list.component.html',
    styleUrls: ['./wl-rate-race-lootbox-list.component.scss'],
    providers: [GaSearchManagementStateService],
})
export class WlRateRaceLootBoxListComponent implements OnInit {
    public result: GaPagedResult<WlRateRaceLootBoxListModel>;
    public tableData: GaTableData<WlRateRaceLootBoxListModel>;
    public viewSearchModel: PagingViewModel = new PagingViewModel();

    constructor(private readonly _service: BattlePassRateRaceService,
                private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlRateRaceLootBoxListModel>>,
                private readonly _router: Router) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public add(): void {
        this._router.navigate(['/menu/raterace/lootBox/edit']);
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._service.lootBoxList(form as WlRateRaceLootBoxPagingForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlRateRaceLootBoxListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.name,
                (elem) => ['/menu/raterace/lootBox/edit/', elem.id],
                {title: 'Название', widthSize: 550}
            )
            .addSimpleColumn((elem) => elem.description, {title: 'Описание', widthSize: 550})
            .addSimpleColumn((elem) => elem.itemsCount, {title: 'Кол-во предметов', widthSize: 250})
            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
