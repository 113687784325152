import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';
import { WlBattlePassTaskTypes, WlCrmTaskTargetTypes, WlTaskComparisonConditionsTypes } from 'src/app/modules/autogen/BattlePass';
import { WlCrmTaskGroupViewModel } from '../../../models/wl-crm-task-group-view-model';

@Component({
    selector: 'app-wl-battle-pass-task-express',
    templateUrl: './wl-crm-task-crud-express.component.html',
    styleUrls: ['../wl-crm-task-crud.component.scss'],
})
export class WlCrmTaskCrudExpressComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: WlCrmTaskGroupViewModel;
    @Output()
    public change = new EventEmitter<void>();
    public moreEqualStrictConditionalType: WlTaskComparisonConditionsTypes[] = [WlTaskComparisonConditionsTypes.MoreEqual];
    public singleValuesConditionalTypes: WlTaskComparisonConditionsTypes[] = [
        WlTaskComparisonConditionsTypes.MoreEqual,
        WlTaskComparisonConditionsTypes.Equal,
        WlTaskComparisonConditionsTypes.LessEqual,
        WlTaskComparisonConditionsTypes.Less,
        WlTaskComparisonConditionsTypes.More,
        WlTaskComparisonConditionsTypes.Equal
    ];

    constructor() {
    }

    public ngOnDestroy(): void {
    }


    public ngOnInit(): void {
        if (isNullOrUndefined(this.viewModel)) {
            throw new Error('ViewModel not implemented.');
        }
    }

    get isDepositsType(): boolean {
        return this.viewModel.taskType.strictValue === WlBattlePassTaskTypes.Deposits;
    }

    get isByCountType(): boolean {
        return !this.isDepositsType && this.viewModel.targetType.hasStrictValue
            && this.viewModel.targetType.strictValue === WlCrmTaskTargetTypes.ByCount;
    }

    get isWinType(): boolean {
        return this.viewModel.taskType.hasStrictValue
            && this.viewModel.taskType.strictValue === WlBattlePassTaskTypes.Win;
    }

    get isBySumType(): boolean {
        return !this.isDepositsType && this.viewModel.targetType.hasStrictValue
            && this.viewModel.targetType.strictValue === WlCrmTaskTargetTypes.BySum;
    }

    get isBetType(): boolean {
        return this.viewModel.taskType.hasStrictValue
            && this.viewModel.taskType.strictValue === WlBattlePassTaskTypes.Bets;
    }
}
