import {Component, inject, OnInit} from '@angular/core';
import {
    GaMessagingService, GaStrictModelFactory,
    isNullOrUndefined,
    StrictError, StrictFormControl,
} from '@koddington/ga-common';
import {
  PartnersService,
  TaskTrackerPartnerCreateForm, TaskTrackerPartnersBtagSelectorViewModel, TaskTrackerPartnersPromoSelectorViewModel,
  TaskTrackerPartnerUpdateForm, WlBtag, WlPromoGroup,
  WlTaskTrackerPartnerModel,
} from '../../../autogen/TaskTrackerPartner';
import {Observable, Subject} from 'rxjs';
import {WlPartnerRegistriesViewModel} from '../../models/wl-partner-registries-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, finalize, map, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {PartnerRegistriesCrudValidator} from './validator/partner-registries-crud-validator';
import {WlTaskTrackerPartnerStateTypesStrategy} from '../../strategy/wl-task-tracker-partner-state-types-strategy';
import {WlResult} from 'src/app/models/common/results/wl-result';
import {MediaPromoStrategy} from '../../../battle-pass/strategies/media-promo-strategy';
import {MediaBtagStrategy} from '../../../battle-pass/strategies/media-btag-strategy';
import {PartnersPromoSelectorViewModel} from './models/partners-promo-selector-view-model';
import {PartnersBtagSelectorViewModel} from './models/partners-btag-selector-view-model';
import gaMap = GaStrictModelFactory.gaMap;

@UntilDestroy()
@Component({
  selector: 'app-partner-registries-crud-crud',
  templateUrl: './app-partner-registries-crud.component.html',
  styleUrls: ['./app-partner-registries-crud.component.scss'],
  providers: [MediaPromoStrategy, MediaBtagStrategy]
})
export class WlPartnerRegistriesCrudComponent implements OnInit {
  public loading: boolean;
  public viewModel: WlPartnerRegistriesViewModel = new WlPartnerRegistriesViewModel();
  public readonly userManipulationsSource = new Subject<void>();
  public errors: StrictError[] = [];
  public partnerStateStrategy: WlTaskTrackerPartnerStateTypesStrategy;
  protected readonly mediaPromoStrategy = new MediaPromoStrategy(inject(PartnersService));
  protected readonly mediaBtagStrategy = new MediaBtagStrategy(inject(PartnersService));
  protected mediaPromoAutocomplete: StrictFormControl<WlPromoGroup> = new StrictFormControl<WlPromoGroup>(null);
  protected mediaBtagAutocomplete: StrictFormControl<WlBtag> = new StrictFormControl<WlBtag>(null);

  private readonly validator = new PartnerRegistriesCrudValidator();
  private updateDataLoad$: Observable<WlResult<WlTaskTrackerPartnerModel>>;
  private mediaSettingsLimit = 99;

  constructor(
    private readonly _service: PartnersService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _messaging: GaMessagingService
  ) {
  }

  ngOnInit(): void {
    this.partnerStateStrategy = new WlTaskTrackerPartnerStateTypesStrategy();

    this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
      this.errors = this.validator.validate(this.viewModel);
    });

    this.mediaPromoAutocomplete.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.mediaPromoAutocomplete.hasStrictValue) {
        this.addItem(true);
        this.userManipulationsSource.next();
      }
    });

    this.mediaBtagAutocomplete.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.mediaBtagAutocomplete.hasStrictValue) {
        this.addItem(false);
        this.userManipulationsSource.next();
      }
    });

    this.subscribeLoad();
  }

  public addItem(isPromo: boolean): void {
    if (isPromo) {
      const promoToAdd = new PartnersPromoSelectorViewModel();
      this.mapFromPromoAutocomplete(promoToAdd);
      this.preventPromoDuplicationThenAdd(promoToAdd);
      this.userManipulationsSource.next();
      return;
    }
    const btagToAdd = new PartnersBtagSelectorViewModel();
    this.mapFromBtagAutocomplete(btagToAdd);
    this.preventBtagDuplicationThenAdd(btagToAdd);
    this.userManipulationsSource.next();
  }

  public deleteItem(index: number, isPromo: boolean): void {
    if (isPromo && this.viewModel.mediaPromoSettings.strictValue.length > 0) {
      this.viewModel.mediaPromoSettings.strictValue =
        this.viewModel.mediaPromoSettings.strictValue.filter((u, k) => k !== index);
      this.userManipulationsSource.next();
      return;
    }
    if (this.viewModel.mediaBtagSettings.strictValue.length > 0) {
      this.viewModel.mediaBtagSettings.strictValue =
        this.viewModel.mediaBtagSettings.strictValue.filter((u, k) => k !== index);
      this.userManipulationsSource.next();
    }
  }

  public save(): void {
    const error = this.validator.validate(this.viewModel);

    if (error.length) return;

    if (this.viewModel.id.hasStrictValue) {
      this.update();
      return;
    }
    this.create();
  }

  private subscribeLoad(): void {
    this._route.paramMap
      .pipe(
        map((params) => params.get('id')),
        take(1)
      )
      .subscribe((id) => {
        if (isNullOrUndefined(id)) return;
        const nId = Number(id);
        this.loading = true;
        if (isNaN(nId)) return;


        this.viewModel.id.strictValue = nId;
        this.viewModel.virtualHostTitle.disable();
        this.viewModel.externalId.disable();
        this.updateDataLoad$ = this._service.getForUpdate(nId).pipe(
          filter((value) => !this._messaging.tryShowError(value)),
          untilDestroyed(this),
          finalize(() => (this.loading = false))
        );
      });

    if (this.updateDataLoad$) {
      this.updateDataLoad$.subscribe((response) => {
        this.loadModel(response.result);
        this.loading = false;
      });
      return;
    }
    this.userManipulationsSource.next();
  }

  private mapFromPromoAutocomplete(model: PartnersPromoSelectorViewModel) {
    model.promoId.strictValue = this.mediaPromoAutocomplete?.strictValue?.id;
    model.promoName.strictValue = this.mediaPromoAutocomplete?.strictValue?.name;
    model.channelId.strictValue = this.mediaPromoAutocomplete?.strictValue?.mediaChannel?.id;
    model.channelName.strictValue = this.mediaPromoAutocomplete?.strictValue?.mediaChannel?.name;
    model.sourceId.strictValue = this.mediaPromoAutocomplete?.strictValue?.mediaSource?.id;
    model.sourceName.strictValue = this.mediaPromoAutocomplete?.strictValue?.mediaSource?.name;
    return model;
  }

  private mapFromBtagAutocomplete(model: PartnersBtagSelectorViewModel) {
    model.btagId.strictValue = this.mediaBtagAutocomplete?.strictValue?.id;
    model.btagName.strictValue = this.mediaBtagAutocomplete?.strictValue?.name;
    model.channelId.strictValue = this.mediaBtagAutocomplete?.strictValue?.mediaChannel?.id;
    model.channelName.strictValue = this.mediaBtagAutocomplete?.strictValue?.mediaChannel?.name;
    model.sourceId.strictValue = this.mediaBtagAutocomplete?.strictValue?.mediaSource?.id;
    model.sourceName.strictValue = this.mediaBtagAutocomplete?.strictValue?.mediaSource?.name;
    return model;
  }

  private create(): void {
    this.loading = true;
    const form = GaStrictModelFactory.fromStrictToModel(
      TaskTrackerPartnerCreateForm,
      this.viewModel
    );
    form.mediaPromoSettings = this.mapPromoItems();
    form.mediaBtagSettings = this.mapBtagItems();

    this._service
      .add(form)
      .pipe(
        filter((value) => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.showListPartner();
        this.loading = false;
      });
  }


  private mapPromoItems(): TaskTrackerPartnersPromoSelectorViewModel[] {
    return this.viewModel.mediaPromoSettings.strictValue.map((val) => {
      const promoForm = new TaskTrackerPartnersPromoSelectorViewModel();

      promoForm.promoId = val?.promoId?.strictValue;
      promoForm.isPromoEnabled = val?.isPromoEnabled?.strictValue;
      promoForm.isSourceEnabled = val?.isSourceEnabled?.strictValue;
      promoForm.isChannelEnabled = val?.isChannelEnabled?.strictValue;

      return promoForm;
    });
  }

  private mapBtagItems(): TaskTrackerPartnersBtagSelectorViewModel[] {
    return this.viewModel.mediaBtagSettings.strictValue.map((val) => {
      const btagForm = new TaskTrackerPartnersBtagSelectorViewModel();

      btagForm.btagId = val?.btagId?.strictValue;
      btagForm.isBtagEnabled = val?.isBtagEnabled?.strictValue;
      btagForm.isSourceEnabled = val?.isSourceEnabled?.strictValue;
      btagForm.isChannelEnabled = val?.isChannelEnabled?.strictValue;

      return btagForm;
    });
  }

  private update(): void {
    this.loading = true;
    const form = GaStrictModelFactory.fromStrictToModel(
      TaskTrackerPartnerUpdateForm,
      this.viewModel
    );
    form.mediaPromoSettings = this.mapPromoItems();
    form.mediaBtagSettings = this.mapBtagItems();

    this._service
      .update(form)
      .pipe(
        filter((value) => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.showListPartner();
        this.loading = false;
      });
  }

  private loadModel(model: WlTaskTrackerPartnerModel): void {
    GaStrictModelFactory.fromModelToStrict(this.viewModel, model, [
      {
        sourceMapProperty: 'mediaPromoSettings',
        targetMapProperty: 'mediaPromoSettings',
        mapObject: gaMap(model.mediaPromoSettings, (value) => {
          const mapped = new PartnersPromoSelectorViewModel();
          GaStrictModelFactory.fromModelToStrict(mapped, value);
          return mapped;
        }),
      },
      {
        sourceMapProperty: 'mediaBtagSettings',
        targetMapProperty: 'mediaBtagSettings',
        mapObject: gaMap(model.mediaBtagSettings, (value) => {
          const mapped = new PartnersBtagSelectorViewModel();
          GaStrictModelFactory.fromModelToStrict(mapped, value);
          return mapped;
        }),
      },
    ], null);
  }

  private showListPartner(): void {
    this._router.navigate(['menu', 'dictionary', 'partner']);
  }

  private preventPromoDuplicationThenAdd(promo: PartnersPromoSelectorViewModel) {
    if (this.viewModel.mediaBtagSettings.strictValue.length + 1 > this.mediaSettingsLimit) {
      this._messaging.showMessage('Ошибка. Нельзя добавлять количество настроек больше ' + this.mediaSettingsLimit.toString());
      return;
    }
    if (this.viewModel.mediaPromoSettings.strictValue.some(u => u.promoId.value === promo.promoId.value)) {
      this._messaging.showMessage('Ошибка. Выбранная настройка Promo уже добавлена');
      return;
    }
    this.viewModel.mediaPromoSettings.strictValue.push(promo);
    this._messaging.showMessage('Добавлена настройка Promo');
  }

  private preventBtagDuplicationThenAdd(btag: PartnersBtagSelectorViewModel) {
    if (this.viewModel.mediaBtagSettings.strictValue.length + 1 > this.mediaSettingsLimit) {
      this._messaging.showMessage('Ошибка. Нельзя добавлять количество настроек больше ' + this.mediaSettingsLimit.toString());
      return;
    }
    if (this.viewModel.mediaBtagSettings.strictValue.some(u => u.btagId.value === btag.btagId.value)) {
      this._messaging.showMessage('Ошибка. Выбранная настройка Btag уже добавлена');
      return;
    }
    this.viewModel.mediaBtagSettings.strictValue.push(btag);
    this._messaging.showMessage('Добавлена настройка Btag');
  }

  get pageTitle(): string {
      return this.viewModel.id.hasStrictValue
          ? `Редактирование партнёра регистрации №${this.viewModel.id.strictValue}'`
          : 'Добавление партнёра регистрации';
  }

  get isValid(): boolean {
    return this.errors.length === 0;
  }
}
