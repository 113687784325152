import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { IPagingViewModel } from '@koddington/ga-common';
import { WlDailyTaskStates, WlPromoTypes } from '../../../../autogen/DailyTasks';


export class DailyTasksDaysListSearchViewModel implements IPagingViewModel {
    id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    term: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    dayType: StrictFormControl<WlPromoTypes> = StrictFormControlBuilder.buildNullNumber();
    state: StrictFormControl<WlDailyTaskStates> = StrictFormControlBuilder.buildNullNumber();
    crmTaskGroupId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    lootBoxId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    count: StrictFormControl<number> = new StrictFormControl<number>(30);
}
