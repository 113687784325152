import { StrictFormControl } from '@koddington/ga-common';
import { WlTaskTrackerRewardTypes } from '../../autogen/TaskTrackerRewards';

export class WlPartnerRewardsViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public type: StrictFormControl<WlTaskTrackerRewardTypes> = new StrictFormControl<WlTaskTrackerRewardTypes>(null);
    public amount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public freeBetId: StrictFormControl<number> = new StrictFormControl<number>(null);
}
