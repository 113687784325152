import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RateRaceNicknameSearchViewModel } from '../../models/wl-rate-race-nick-name-view-model';
import { Router } from '@angular/router';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { WlRateRaceStatusModerationStrategy } from './strategy/wl-rate-race-nickname-moderation.strategy';
import { WlRateRaceNickNameEditViewModel } from '../../models/wl-rate-race-nick-name-edit-view-model';
import {
    formatDateTime,
    GaConfirmationService,
    GaMessagingService,
    GaPagedResult,
    GaPagingForm,
    GaTableCellTemplate,
    GaTableData,
    isNullOrUndefined,
    StrictFormControl,
    stringIsNullOrEmpty
} from '@koddington/ga-common';
import {
    BattlePassRateRaceService,
    WlEntityActors,
    WlRateRaceNicknameModel,
    WlRateRaceNicknamePagingForm,
    WlRateRaceNicknameUpdateForm,
    WlRateRaceNicknameUpdateForms,
    WlRateRaceStatusModeration,
    WlRateRaceStatusModerationExtensions
} from 'src/app/modules/autogen/BattlePass';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { WlBlockUiService } from '../../../shared/services/wl-block-ui-service';

@UntilDestroy()
@Component({
    selector: 'app-wl-rate-nickname-moderation',
    templateUrl: './wl-rate-race-nickname-moderation.component.html',
    styleUrls: ['./wl-rate-race-nickname-moderation.component.scss'],
    providers: [GaSearchManagementStateService]
})
export class WlRateRaceNicknameModerationComponent implements OnInit {
    @ViewChild('statusModerate') statusModerate: TemplateRef<any>;
    @ViewChild('isModerate') isModerate: TemplateRef<any>;

    protected searchViewModel: RateRaceNicknameSearchViewModel = new RateRaceNicknameSearchViewModel();
    protected tableData: GaTableData<WlRateRaceNickNameEditViewModel>;
    protected readonly userManipulationsSource = new Subject<void>();
    protected pageResult: GaPagedResult<WlRateRaceNicknameModel>;
    protected editViewModels: WlRateRaceNickNameEditViewModel[];
    protected allItemButtonClick = false;

    constructor(
        protected readonly _rateRaceStatusModerationStrategy: WlRateRaceStatusModerationStrategy,
        protected readonly _confirmation: GaConfirmationService,
        private readonly _rateRaceService: BattlePassRateRaceService,
        private readonly _router: Router,
        private readonly _messages: GaMessagingService,
        private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlRateRaceNicknameModel>>,
        private readonly _blockUi: WlBlockUiService
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchService.nextSearch(page);
    }

    public pageFiltered(page: GaPagingForm = null): void {
        this._searchService.nextSearch(page, true);
    }

    public getStatus(userId: number): string {
        const user = this.editViewModels.find((u) => u.userId === userId);

        return isNullOrUndefined(user.statusModeration.strictValue)
            ? WlRateRaceStatusModerationExtensions.format(WlRateRaceStatusModeration.Undefined)
            : WlRateRaceStatusModerationExtensions.format(user.statusModeration.strictValue);
    }

    public isCheckByUserId(userId: number): boolean {
        const user = this.editViewModels.find((u) => u.userId === userId);
        user.isModerate.valueChanges.pipe(take(1)).subscribe((u) => {
            if (u) {
                user.statusModeration.enable();
                return;
            }
            user.statusModeration.disable();
            return;
        });

        return user.isModerate.strictValue;
    }

    public getByUserId(userId: number): StrictFormControl<boolean> {
        const user = this.editViewModels.find((u) => u.userId === userId);
        return user.isModerate;
    }

    public getStatusByUserId(userId: number): StrictFormControl<WlRateRaceStatusModeration> {
        const user = this.editViewModels.find((u) => u.userId === userId);
        return user.statusModeration;
    }

    public chooseAndFill(): void {
        this.editViewModels.forEach((u) => {
            u.isModerate.strictValue = true;
            u.statusModeration.strictValue = WlRateRaceStatusModeration.Accept;
        });
        this.allItemButtonClick = true;
    }

    public chooseAndReset(): void {
        this.init();
        this.allItemButtonClick = false;
    }

    public save(): void {
        this._confirmation
            .openDialog('Вы уверены, что хотите сохранить изменения выбранных элементов?', 'Подтвердить')
            .pipe(
                take(1),
                filter(isConfirm => {
                    if (!isConfirm) {
                        this._messages.showMessage('Отмена сохранения');
                        return;
                    }
                    return isConfirm as boolean;
                }),
                mergeMap(() => {
                    this._blockUi.start();
                    return this.updateModerate();
                }),
                finalize(() => {
                    this._blockUi.stop();
                })
            )
            .subscribe((result) => {
                if (result.isCorrect) {
                    this._messages.showMessage('Модерация никнеймов сохранена');
                    this.refresh('/menu/raterace/rate-race-nickname-moderation');
                    return;
                }
                this._messages.showMessage('Модерация никнеймов не сохранена');
                return;
            });
    }

    public updateModerate(): Observable<WlResult<boolean>> {
        const filterItems = this.editViewModels.filter((x) => x.isModerate.strictValue);
        const formToUpdate = filterItems.map((x) => {
            const itemToUpdate: WlRateRaceNicknameUpdateForm = new WlRateRaceNicknameUpdateForm();
            itemToUpdate.userId = x.userId;
            itemToUpdate.statusModeration = x.statusModeration.strictValue;
            itemToUpdate.nickname = x.nickname;
            return itemToUpdate;
        });

        if (formToUpdate.length === 0) {
            const result: WlResult<boolean> = new WlResult<boolean>({isCorrect: false, error: null});
            return new Observable((u) => u.next(result));
        }

        const form: WlRateRaceNicknameUpdateForms = new WlRateRaceNicknameUpdateForms();
        form.updateForm = formToUpdate;
        return this._rateRaceService.updateNicknames(form);
    }

    private init(): void {
        this._searchService.init(u => this._rateRaceService.nicknameList(u as WlRateRaceNicknamePagingForm), this.searchViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pageResult = res;
            if (!isNullOrUndefined(this.editViewModels)) {
                this.editViewModels.length = 0;
            }

            this.editViewModels = this.mapBackendToEditViewModels(res.results);
            this.mapToTable();
            this._searchService.loadingStatus = false;
            this.allItemButtonClick = false;
        });
    }

    private mapBackendToEditViewModels(backendModels: WlRateRaceNicknameModel[]): WlRateRaceNickNameEditViewModel[] {
        if (isNullOrUndefined(backendModels)) {
            return [];
        }
        return backendModels.map((u) => {
            const rrEditViewModel = new WlRateRaceNickNameEditViewModel();
            rrEditViewModel.userId = u.userId;
            rrEditViewModel.atUpdateModeration = u.atUpdateModeration;
            rrEditViewModel.moderator = this.getModeratorName(u.entityActors);
            rrEditViewModel.nickname = u.nickname;
            rrEditViewModel.statusModeration.strictValue = u.statusModeration;
            rrEditViewModel.currentLevel = u.levels;
            return rrEditViewModel;
        });
    }

    private refresh(url: string): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, [url]);
    }

    private mapToTable() {
        const data = new GaTableData<WlRateRaceNickNameEditViewModel>();

        this.tableData = data
            .addTemplateColumn(
                new GaTableCellTemplate(this.isModerate, (elem) => {
                    return {
                        userid: elem.userId,
                    };
                }),
                {title: 'Модерация', widthSize: 110}
            )
            .addSimpleColumn((elem) => elem.userId.toString(), {title: 'Логин', widthSize: 110})
            .addSimpleColumn((elem) => elem.nickname, {title: 'Никнейм', widthSize: 200})
            .addTemplateColumn(
                new GaTableCellTemplate(this.statusModerate, (elem) => {
                    return {
                        userid: elem.userId,
                    };
                }),
                {title: 'Статус модерации', widthSize: 200}
            )
            .addSimpleColumn((elem) => formatDateTime(elem.atUpdateModeration), {title: 'Дата последнего изменения', widthSize: 250})
            .addSimpleColumn((elem) => elem.currentLevel.map((u) => u.currentLevel.toString() + ` (${u.seasonId.toString()})`).join(','), {
                title: 'Текущие уровни',
                widthSize: 250,
            })
            .addSimpleColumn((elem) => elem.moderator, {title: 'Модератор', widthSize: 150})
            .setData(this.editViewModels);
    }

    private getModeratorName(actor: WlEntityActors): string {
        if (!stringIsNullOrEmpty(actor.editorAccountLogin)) {
            return actor.editorAccountLogin;
        }

        if (!stringIsNullOrEmpty(actor.creatorAccountLogin)) {
            return actor.creatorAccountLogin;
        }

        return '';
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
