import { Routes } from '@angular/router';
import { WlCrmTaskListComponent } from './components/wl-crm-task-list/wl-crm-task-list.component';
import { WlCrmTaskCrudComponent } from './components/wl-crm-task-crud/wl-crm-task-crud.component';
import { WlBattlePassGameTaskListComponent } from './components/wl-battle-pass-game-task-list/wl-battle-pass-game-task-list.component';
import { WlBattlePassGameTaskCrudComponent } from './components/wl-battle-pass-game-task-crud/wl-battle-pass-game-task-crud.component';
import { WlBattlePassSeasonListComponent } from './components/wl-battle-pass-season-list/wl-battle-pass-season-list.component';
import { WlBattlePassSeasonCrudComponent } from './components/wl-battle-pass-season-crud/wl-battle-pass-season-crud.component';
import { WlBattlePassRewardListComponent } from './components/wl-battle-pass-reward-list/wl-battle-pass-reward-list.component';
import { WlBattlePassRewardViewComponent } from './components/wl-battle-pass-reward-view/wl-battle-pass-reward-view.component';
import { WlBattlePassGameLootBoxListComponent } from './components/wl-battle-pass-game-loot-box-list/wl-battle-pass-game-loot-box-list.component';
import { WlBattlePassGameLootBoxCrudComponent } from './components/wl-battle-pass-game-loot-box-crud/wl-battle-pass-game-loot-box-crud.component';
import { WlBattlePassFreeBetLootBoxListComponent } from './components/wl-battle-pass-free-bet-loot-box-list/wl-battle-pass-free-bet-loot-box-list.component';
import { WlBattlePassFreeBetLootBoxCrudComponent } from './components/wl-battle-pass-free-bet-loot-box-crud/wl-battle-pass-free-bet-loot-box-crud.component';
import { WlBattlePassUserInfoComponent } from './components/wl-battle-pass-user-info/wl-battle-pass-user-info.component';
import { WlBattlePassGameTaskTemplatesComponent } from './components/wl-battle-pass-game-task-templates/wl-battle-pass-game-task-templates.component';
import { WlBattlePassGameTaskTemplateEditComponent } from './components/wl-battle-pass-game-task-template-edit/wl-battle-pass-game-task-template-edit.component';
import { WlBattlePassBetsTaskStatisticsComponent } from './components/statistics/wl-battle-pass-bets-task-statistics/wl-battle-pass-bets-task-statistics.component';
import { WlBattlePassDepositsTasksStatisticsComponent } from './components/statistics/wl-battle-pass-deposits-tasks-statistics/wl-battle-pass-deposits-tasks-statistics.component';
import {
    WlBattlePassFilteredOutBetsStatisticsComponent
} from './components/statistics/wl-battle-pass-filtered-out-bets-statistics/wl-battle-pass-filtered-out-bets-statistics.component';
import {
    WlBattlePassFilteredOutBetsLinesStatisticsComponent
} from './components/statistics/wl-battle-pass-filtered-out-bets-lines-statistics/wl-battle-pass-filtered-out-bets-lines-statistics.component';

export const WlBattlePassRoutes: Routes = [
    {
        path: 'battlePass',
        children: [
            {
                path: 'task',
                component: WlCrmTaskListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-task',
                    routeName: 'Список букмекерских заданий',
                },
            },
            {
                path: 'task/edit',
                component: WlCrmTaskCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-task',
                    routeName: 'Редактирование букмекерского задания',
                },
            },
            {
                path: 'task/edit/:id',
                component: WlCrmTaskCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-task',
                    routeName: 'Редактирование букмекерского задания',
                },
            },
            {
                path: 'gameTask',
                component: WlBattlePassGameTaskListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-game-task',
                    routeName: 'Список игровых заданий',
                },
            },
            {
                path: 'gameTask/edit',
                component: WlBattlePassGameTaskCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-game-task',
                    routeName: 'Редактирование игрового задания',
                },
            },
            {
                path: 'gameTask/edit/:id',
                component: WlBattlePassGameTaskCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-crm-game-task',
                    routeName: 'Редактирование игрового задания',
                },
            },
            {
                path: 'season',
                component: WlBattlePassSeasonListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-season',
                    routeName: 'Список сезонов',
                },
            },
            {
                path: 'season/edit',
                component: WlBattlePassSeasonCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-season',
                    routeName: 'Редактирование сезона',
                },
            },
            {
                path: 'season/edit/:id',
                component: WlBattlePassSeasonCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-season',
                    routeName: 'Редактирование сезона',
                },
            },
            {
                path: 'reward',
                component: WlBattlePassRewardListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-reward-list',
                    routeName: 'Список наград',
                },
            },
            {
                path: 'reward/view/:id',
                component: WlBattlePassRewardViewComponent,
                data: {
                    highlightMenuItem: 'battle-pass-reward',
                    routeName: 'Награда',
                },
            },
            {
                path: 'lootBox',
                component: WlBattlePassGameLootBoxListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-loot-box',
                    routeName: 'Список лутбоксов',
                },
            },
            {
                path: 'lootBox/edit',
                component: WlBattlePassGameLootBoxCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-loot-box',
                    routeName: 'Создание игрового лутбокса',
                },
            },
            {
                path: 'lootBox/edit/:id',
                component: WlBattlePassGameLootBoxCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-loot-box',
                    routeName: 'Редактирование игрового лутбокса',
                },
            },
            {
                path: 'freeBetLootBox',
                component: WlBattlePassFreeBetLootBoxListComponent,
                data: {
                    highlightMenuItem: 'battle-pass-free-bet-loot-box',
                    routeName: 'Список фрибетных лутбоксов',
                },
            },
            {
                path: 'freeBetLootBox/create',
                component: WlBattlePassFreeBetLootBoxCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-free-bet-loot-box',
                    routeName: 'Создание фрибетного лутбокса',
                },
            },
            {
                path: 'freeBetLootBox/edit/:id',
                component: WlBattlePassFreeBetLootBoxCrudComponent,
                data: {
                    highlightMenuItem: 'battle-pass-free-bet-loot-box',
                    routeName: 'Редактирование фрибетного лутбокса',
                },
            },
            {
                path: 'userInfo',
                component: WlBattlePassUserInfoComponent,
                data: {
                    highlightMenuItem: 'battle-pass-user-info',
                    routeName: 'Профиль юзера',
                },
            },
            {
                path: 'gameTaskTemplates',
                component: WlBattlePassGameTaskTemplatesComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-task-templates',
                    routeName: 'Шаблоны игровых заданий',
                },
            },
            {
                path: 'gameTaskTemplates/edit/:id',
                component: WlBattlePassGameTaskTemplateEditComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-task-templates',
                    routeName: 'Редактирование шаблона игровых заданий',
                },
            },
            {
                path: 'gameTaskTemplates/edit',
                component: WlBattlePassGameTaskTemplateEditComponent,
                data: {
                    highlightMenuItem: 'battle-pass-game-task-templates',
                    routeName: 'Создание шаблона игровых заданий',
                },
            },
            {
                path: 'statistics/betsForTasks',
                component: WlBattlePassBetsTaskStatisticsComponent,
                data: {
                    highlightMenuItem: 'battle-pass-statistics-bets-for-tasks',
                    routeName: 'Статистика ставок для заданий',
                },
            },
            {
                path: 'statistics/depositsForTasks',
                component: WlBattlePassDepositsTasksStatisticsComponent,
                data: {
                    highlightMenuItem: 'battle-pass-statistics-deposits-for-tasks',
                    routeName: 'Статистика депозитов для заданий',
                },
            },
            {
                path: 'statistics/filteredOutBets',
                component: WlBattlePassFilteredOutBetsStatisticsComponent,
                data: {
                    highlightMenuItem: 'battle-pass-statistics-filtered-out-bets',
                    routeName: 'Незачтенные ставки',
                },
            },
            {
                path: 'statistics/filteredOutBetsLines',
                component: WlBattlePassFilteredOutBetsLinesStatisticsComponent,
                data: {
                    highlightMenuItem: 'battle-pass-statistics-filtered-out-bets-lines',
                    routeName: 'Незачтенные линии ставок',
                },
            },
        ],
    },
];
