import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GaMessagingService, GaStrictModelFactory, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize, map, take } from 'rxjs/operators';
import { WlPartnerRewardsViewModel } from '../../models/wl-partner-rewards-view-model';
import { WlTaskTrackerRewardTypesStrategy } from '../../strategy/wl-task-tracker-reward-types-strategy';
import { RewardsService, TaskTrackerRewardCreateForm, TaskTrackerRewardUpdateForm, WlTaskTrackerRewardModel, WlTaskTrackerRewardTypes, } from '../../../autogen/TaskTrackerRewards';
import { PartnerRewardsCrudValidator } from './validator/partner-rewards-crud-validator';
import { WlResult } from 'src/app/models/common/results/wl-result';

@UntilDestroy()
@Component({
    selector: 'app-partner-rewards-crud',
    templateUrl: './partner-rewards-crud.component.html'
})
export class PartnerRewardsCrudComponent implements OnInit {
    public loading: boolean;
    public viewModel: WlPartnerRewardsViewModel = new WlPartnerRewardsViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    private readonly validator = new PartnerRewardsCrudValidator();
    public rewardTypeStrategy: WlTaskTrackerRewardTypesStrategy;
    private updateDataLoad$: Observable<WlResult<WlTaskTrackerRewardModel>>;

    constructor(
        private readonly _service: RewardsService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _messaging: GaMessagingService
    ) {
    }

    ngOnInit(): void {
        this.rewardTypeStrategy = new WlTaskTrackerRewardTypesStrategy();

        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.subscribeLoad();
    }

    private subscribeLoad(): void {
        this._route.paramMap
            .pipe(
                map((params) => params.get('id')),
                take(1)
            )
            .subscribe((id) => {
                if (isNullOrUndefined(id)) {
                    return;
                }
                const nId = Number(id);
                this.loading = true;

                this.viewModel.id.strictValue = nId;
                this.updateDataLoad$ = this._service.getForUpdate(nId).pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this),
                    finalize(() => (this.loading = false))
                );
            });

        if (this.updateDataLoad$) {
            this.updateDataLoad$.subscribe((response) => {
                this.loadModel(response.result);
                this.viewModel.type.disable();
            });
            return;
        }
        this.userManipulationsSource.next();
    }

    public save(): void {
        const error = this.validator.validate(this.viewModel);

        if (error.length) {
            return;
        }

        if (this.viewModel.id.hasStrictValue) {
            this.update();
            return;
        }

        this.create();
    }

    private create(): void {
        this.loading = true;
        const form = this.getForm(TaskTrackerRewardCreateForm);
        this._service
            .add(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe(() => {
                this.showListPartner();
            });
    }

    private update(): void {
        this.loading = true;
        const form = this.getForm(TaskTrackerRewardUpdateForm);
        this._service
            .update(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe(() => {
                this.showListPartner();
            });
    }

    private getForm<T extends TaskTrackerRewardCreateForm>(builder: new (...args: any[]) => T): T {
        return GaStrictModelFactory.fromStrictToModel(builder, this.viewModel, null, [
                {
                    sourceKey: 'freeBetId',
                    targetKey: 'freeBetId',
                    newValue: this.isFreeBetType ? this.viewModel.freeBetId.strictValue : null,
                }
            ]
        );
    }

    private loadModel(model: WlTaskTrackerRewardModel): void {
        GaStrictModelFactory.fromModelToStrict(this.viewModel, model);
    }

    private showListPartner(): void {
        this._router.navigate(['menu/dictionary/reward']);
    }

    get pageTitle(): string {
        return this.viewModel.id.hasStrictValue
            ? `Редактирование БК награды игрока №${this.viewModel.id.strictValue}'`
            : 'Добавление БК награды игрока';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get isFreeBetType(): boolean {
        return this.viewModel.type.hasStrictValue && this.viewModel.type.strictValue === WlTaskTrackerRewardTypes.FreeBet;
    }
}
