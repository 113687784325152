import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { WlPageNotFoundComponent } from './modules/root/components/page-not-found/app-page-not-found.component';
import { WlRootModule } from './modules/root/root.module';
import {
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './modules/app-main/app.component';
import { WlAuthStateService } from './modules/root/components/authorize/services/auth-state-service';
import localeRu from '@angular/common/locales/ru';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [AppComponent, WlPageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    WlRootModule,
    RouterModule.forRoot(AppRoutes),
  ],
  providers: [WlAuthStateService, {provide: APP_BASE_HREF, useValue: '/bpadmin/'}],
  bootstrap: [AppComponent],
})
export class AppModule {}
