import { WlBattlePassRewardModel, WlBattlePassRewardSearchForm } from '../../autogen/BattlePass';
import { IAutocompleteItem } from '../../shared/components/strict-autocomplete/contracts/app-autocomplete-item';
import { WlBattlePassRewardsScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-rewards.scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';
import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class WlBattlePassRewardAutocompleteStrategy extends ScrollStrategy<WlBattlePassRewardModel, WlBattlePassRewardSearchForm> {
    constructor(protected readonly _apiClient: WlBattlePassRewardsScrollServiceAdapter) {
        super(_apiClient);
    }

    public convert(model: WlBattlePassRewardModel): IAutocompleteItem<WlBattlePassRewardModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name,
                key: model.id?.toString(),
            };
        });
    }

    protected override load(item: WlBattlePassRewardSearchForm, fnRules: (form: WlBattlePassRewardSearchForm) => boolean = null): Observable<any> {
        return this._apiClient.search(item).pipe(
            take(1),
            filter((v) => v.isCorrect),
            map((result) => result.result),
        );
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
