import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BattlePassFilteredOutBetsLinesPagingForm, BattlePassStatisticsService, WlBattlePassFilteredOutBetLinesModel } from '../../../../autogen/BattlePass';
import { formatDateTime, GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { GaTableData } from '@koddington/ga-common';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { WlBpFilteredOutBetLinesPagingViewModel } from './view-models/wl-bp-filtered-out-bet-lines.paging-view-model';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-filtered-out-bets-lines-statistics',
    templateUrl: './wl-battle-pass-filtered-out-bets-lines-statistics.component.html',
    styleUrls: ['./wl-battle-pass-filtered-out-bets-lines-statistics.component.scss'],
    providers: [GaSearchManagementStateService]
})
export class WlBattlePassFilteredOutBetsLinesStatisticsComponent implements OnInit {
    protected logs: GaPagedResult<WlBattlePassFilteredOutBetLinesModel>;
    protected pagingViewModel = new WlBpFilteredOutBetLinesPagingViewModel();

    tableData: GaTableData<WlBattlePassFilteredOutBetLinesModel>;

    constructor(private readonly _battlePassStatisticsService: BattlePassStatisticsService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlBattlePassFilteredOutBetLinesModel>>
    ) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public pageFiltered(page: GaPagingForm = null): void {
        this._searchService.nextSearch(page, true);
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchService.nextSearch(page);
    }

    private init(): void {
        this._searchService.init(u => this._battlePassStatisticsService.getFilteredOutBetLinesList(u as BattlePassFilteredOutBetsLinesPagingForm), this.pagingViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.logs = res;
            this.mapToTable(res.results);
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(logs: WlBattlePassFilteredOutBetLinesModel[]): void {
        const data = new GaTableData<WlBattlePassFilteredOutBetLinesModel>();
        this.tableData = data
            .addSimpleColumn((elem) => elem.userId, {title: 'Пользователь', widthSize: 170})
            .addSimpleColumn((elem) => elem.taskId, {title: 'Id задания', widthSize: 170})
            .addSimpleColumn((elem) => elem.betId, {title: 'Id ставки', widthSize: 170})
            .addSimpleColumn((elem) => elem.lineId, {title: 'Id линии', widthSize: 170})
            .addSimpleColumn((elem) => elem.filterName, {title: 'Имя фильтра', widthSize: 350})
            .addSimpleColumn((elem) => formatDateTime(elem.logged), {title: 'Дата сохранения', widthSize: 350})
            .setData(logs);
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
