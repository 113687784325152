import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { WlAuthStateService } from '../authorize/services/auth-state-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IWithToolBarComponent } from '../../../../common/abstracts/i-with-tool-bar-component';
import { isNullOrUndefined } from '@koddington/ga-common';
import { APP_BASE_HREF } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-root-component',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, AfterViewInit {
    protected hasToolbar = false;
    private routerOutletComponent: Component;
    private currentLogin = '';
    private toolbarDrawerFunc: () => void = () => (this.routerOutletComponent as IWithToolBarComponent).toolbarDrawer.toggle();

    constructor(private readonly _userAuthStateService: WlAuthStateService,
                private readonly _changeDetector: ChangeDetectorRef,
                @Inject(APP_BASE_HREF) protected _baseHref: string) {
    }

    ngOnInit(): void {
        this._userAuthStateService.userState
            .pipe(untilDestroyed(this))
            .subscribe((u) => {
                if (!u?.login) {
                    this.currentLogin = 'Гость';
                    return;
                }
                this.currentLogin = u.login;
            });
    }

    public ngAfterViewInit(): void {
        this._changeDetector.detectChanges();
    }

    protected toggleToolbar(): void {
        if (this.hasToolbar) {
            this.toolbarDrawerFunc();
        }
    }

    protected onRouterOutletActivate($event: Component): void {
        this.routerOutletComponent = $event;
        this.validateToolbar();
    }

    private validateToolbar(): void {
        this.hasToolbar = !isNullOrUndefined((this.routerOutletComponent as IWithToolBarComponent)?.hasToolbar);
    }

    get login(): string {
        return this.currentLogin;
    }

    get isLogin(): boolean {
        return this.currentLogin !== '' && this.currentLogin !== 'Гость';
    }
}
