import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAutocompleteStrategy } from './contracts/autocomplete-strategy';
import { IAutocompleteItem } from './contracts/app-autocomplete-item';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-strict-autocomplete',
    templateUrl: './strict-autocomplete.component.html',
})
export class StrictAutocompleteComponent<T> implements OnInit {
    @Input()
    public isSearch = false;
    @Input()
    public isInvisible = false;
    @Input()
    public disabled = false;
    @Input()
    public placeholder = '';
    @Input()
    public control: StrictFormControl<T>;
    @Input()
    public parentControl?: StrictFormControl<any>;
    @Input()
    public strategy: IAutocompleteStrategy<T>;
    @Input()
    public shouldShowErrorList = false;
    @Input()
    public allowPickAll = false;
    @Input()
    public readonly clearField: Subject<void>;
    @Output()
    public userSelect = new EventEmitter<T>();
    @Output()
    public userFinalSelected = new EventEmitter<T>();
    @Output()
    public readonly valueChange = new EventEmitter<void>();
    @Output()
    public readonly onPickAll = new EventEmitter<void>();
    @Output()
    public readonly searchMethod = new EventEmitter<void>();

    public currentItem: IAutocompleteItem<T>;
    public currentItemsSource: IAutocompleteItem<T>[] = [];

    public isActive = false;
    public currentFocus = 0;

    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        map((event) => !this._elementRef.nativeElement.contains(event.target)),
        filter((outside: boolean) => outside)
    );
    private readonly termSource = new Subject<string>();
    private readonly KeyDownCode = 'ArrowDown';
    private readonly KeyUpCode = 'ArrowUp';
    private readonly KeyEnterCode = 'Enter';

    constructor(private _elementRef: ElementRef, private _changeTracker: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (isNullOrUndefined(this.control)) {
            throw new Error('Entity container for dropdown cannot be undefined');
        }
        if (isNullOrUndefined(this.strategy)) {
            throw new Error('Strategy for dropdown cannot be undefined');
        }

        if (!isNullOrUndefined(this.clearField)) {
            this.clearField.pipe(untilDestroyed(this), debounceTime(300)).subscribe(() => {
                this.currentItem = null;
            });
        }

        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe(() => (this.isActive = false));

        this.strategy
            .getSource()
            .pipe(
                map((items) => {
                    return items.map((u) => this.strategy.convert(u));
                }),
                untilDestroyed(this)
            )
            .subscribe((items) => {
                this.currentItemsSource = items;
            });

        this.termSource.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this)).subscribe((term) => this.strategy.updateSource(term));

        this.currentItem = !this.control.hasStrictValue ? null : this.strategy.convert(this.control.strictValue);

        this.control.strictValueSource
            .pipe(
                distinctUntilChanged(),
                map((u: T) => {
                    if (isNullOrUndefined(u)) {
                        return null;
                    }
                    return this.strategy.convert(u);
                }),
                untilDestroyed(this)
            )
            .subscribe((item) => (this.currentItem = item));
    }

    public pickAll(): void {
        this.isActive = false;
        this.resetValue();
        this.onPickAll.emit();
    }

    public onClick(item: IAutocompleteItem<T>): void {
        if (this.control.disabled) {
        }
        this.isActive = false;
        if (isNullOrUndefined(item)) {
            this.userFinalSelected.emit();
            return;
        }
        this.setCurrentItem(item);
        this.control.strictValue = item.entity;
        this.userSelect.emit(this.control.strictValue);
        this.termSource.next(null);
        this.userFinalSelected.emit();
    }

    public onInputChange(event: any): void {
        const term: string = event.target.value;
        this.control.setValue(null, { emitEvent: false });

        if (isNullOrUndefined(term) || term.length === 0) {
            this.resetValue();
        } else {
            this.isActive = true;
        }

        this.termSource.next(term);
        this.userSelect.emit();
    }

    public onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case this.KeyDownCode:
                this.moveSelectionDown();
                return;
            case this.KeyUpCode:
                this.moveSelectionUp();
                return;
            case this.KeyEnterCode:
                if (this.isActive) {
                    const selectedItem = this.currentItemsSource[this.currentFocus];
                    this.onClick(selectedItem);
                } else {
                    this.searchMethod.emit();
                }
                return;
        }
    }

    public onFocus(): void {
        this.isActive = true;
    }

    get isDisabled(): boolean {
        return this.control.disabled || this.parentControl?.disabled || this.disabled;
    }

    private setCurrentItem(item: IAutocompleteItem<T>): void {
        this.currentItem = null;
        this._changeTracker.detectChanges();
        this.valueChange.emit();
        this.currentItem = item;
    }

    private moveSelectionUp() {
        if (this.currentFocus <= 0) {
            this.currentFocus = this.currentItemsSource.length - 1;
        } else {
            this.currentFocus--;
        }
    }

    private moveSelectionDown() {
        if (this.currentFocus >= this.currentItemsSource.length - 1) {
            this.currentFocus = 0;
        } else {
            this.currentFocus++;
        }
    }

    private resetValue(): void {
        this.setCurrentItem(null);
        this.currentFocus = 0;
        this.control.strictValue = null;
    }
}
