import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter} from 'rxjs/operators';
import { BattlePassRewardsService, WlBattlePassRewardModel } from 'src/app/modules/autogen/BattlePass';
import { GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-reward-view',
    templateUrl: './wl-battle-pass-reward-view.component.html',
    styleUrls: ['./wl-battle-pass-reward-view.component.scss']
})
export class WlBattlePassRewardViewComponent implements OnInit {

    public loading: boolean;
    public viewModel: WlBattlePassRewardModel = new WlBattlePassRewardModel();

    constructor(private readonly _messaging: GaMessagingService,
                private readonly _route: ActivatedRoute,
                private readonly _titleService: Title,
                private readonly _battlePassRewardsService: BattlePassRewardsService) { }

    ngOnInit(): void {
        this.loading = true;

        this.viewModel.id = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];

        this.load();
        this._titleService.setTitle(`Награда № ${this.viewModel.id}`);
    }

    private load(): void {
        if (!isNullOrUndefined(this.viewModel.id)) {
            this._battlePassRewardsService.getById(this.viewModel.id)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this)
                ).subscribe((result) => {
                this.viewModel = result.result;
                this.loading = false;
            });
        } else {
            this.loading = false;
        }
    }

    get purpose(): string {
        return `Награда № ${this.viewModel.id}`;
    }
}
