<app-wl-page-header header="Модерация никнеймов"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-input-marging"
        [control]="searchViewModel.seasonId"
        [isSearch]="true"
        placeholder="id сезона"
        (searchMethod)="pageFiltered()"
        (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-input-marging"
        [control]="searchViewModel.fromLevelOrder"
        [isSearch]="true"
        placeholder="Уровень с "
        (searchMethod)="pageFiltered()"
        (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-input-marging"
        [control]="searchViewModel.toLevelOrder"
        [isSearch]="true"
        placeholder="Уровень по"
        (searchMethod)="pageFiltered()"
        (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    ></app-wl-strict-input-number>
    <app-wl-dropdown
        class="wl-app-input-search-wrapper wl-input-marging"
        isSearch="true"
        [options]="{ placeholder: 'Укажите статус' }"
        [strategy]="_rateRaceStatusModerationStrategy"
        (userSelect)="pageFiltered()"
        [control]="searchViewModel.statusModeration"
    >
    </app-wl-dropdown>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-input-marging"
        [control]="searchViewModel.userId"
        [isSearch]="true"
        placeholder="Логин"
        (searchMethod)="pageFiltered()"
        (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-text
        class="wl-input-search-wrapper"
        [control]="searchViewModel.nickname"
        title="Никнейм"
        [isSearch]="true"
        placeholder="Никнейм"
        (searchMethod)="pageFiltered()"
        (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    ></app-wl-strict-input-text>
    <button class="wl-search-btn wl-save-marging-btn" (click)="chooseAndFill()" *ngIf="!allItemButtonClick">
        Выбрать все
    </button>
    <button class="wl-search-btn wl-save-marging-btn" (click)="chooseAndReset()" *ngIf="allItemButtonClick">
        Сбросить все
    </button>
    <button class="wl-search-btn" (click)="save()">Сохранить</button>
</div>
<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>

<ng-template #isModerate let-userid="userid">
    <div class="wl-button-wrapper" *ngIf="!!userid">
        <app-wl-strict-input-boolean
            (userSelect)="userManipulationsSource.next()"
            [control]="getByUserId(userid)"
        ></app-wl-strict-input-boolean>
    </div>
</ng-template>

<ng-template #statusModerate let-userid="userid">
    <div class="wl-button-wrapper" *ngIf="!!userid">
        <div *ngIf="isCheckByUserId(userid)">
            <app-wl-dropdown
                [options]="{ placeholder: 'Укажите статус' }"
                [strategy]="_rateRaceStatusModerationStrategy"
                (userSelect)="userManipulationsSource.next()"
                [control]="getStatusByUserId(userid)"
            >
            </app-wl-dropdown>
        </div>
        <label class="wl-moderate-status-label" *ngIf="!isCheckByUserId(userid)">{{ getStatus(userid) }} </label>
    </div>
</ng-template>

<div class="wl-paging-wrapper">
    <app-wl-paging
        *ngIf="pageResult"
        [pagedResult]="pageResult"
        (pageChangedEvent)="pageChanged($event)"
    ></app-wl-paging>
</div>
