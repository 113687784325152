import { Component, OnInit } from '@angular/core';
import { GaPagedResult, GaPagingForm, PagingViewModel } from '@koddington/ga-common';
import { BattlePassRewardsService, WlBattlePassRewardModel, WlBattlePassRewardSearchForm } from '../../../autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-reward-list',
    templateUrl: './wl-battle-pass-reward-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassRewardListComponent implements OnInit {
    public result: GaPagedResult<WlBattlePassRewardModel>;
    public tableData: GaTableData<WlBattlePassRewardModel>;
    public viewSearchModel: PagingViewModel = new PagingViewModel();

    constructor(private readonly _battlePassRewardsService: BattlePassRewardsService,
                private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlBattlePassRewardModel>>) {
    }

    ngOnInit(): void {
        this.init();
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._battlePassRewardsService.list(form as WlBattlePassRewardSearchForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlBattlePassRewardModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['view', elem.id],
                {title: 'Id предмета', widthSize: 150}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 500})
            .addSimpleColumn((elem) => elem.description, {title: 'Описание', widthSize: 700})
            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
