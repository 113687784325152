<app-loading *ngIf="loading"></app-loading>
<app-wl-page-header [header]="pageTitle"></app-wl-page-header>
<app-wl-strict-input-text class="wl-row"
                       [control]="viewModel.name"
                       title="Название"
                       placeholder="Введите название награды"
                       (valueChange)="userManipulationsSource.next()"
>
</app-wl-strict-input-text>
<app-wl-dropdown class="wl-row"
                     [control]="viewModel.type"
                     [options]="{ placeholder: 'Выберите тип награды', title: 'Состоние' }"
                     [strategy]="rewardTypeStrategy"
                     (userSelect)="userManipulationsSource.next()"
>
</app-wl-dropdown>
<app-wl-strict-input-number class="wl-row"
                         [control]="viewModel.amount"
                         title="Размер вознаграждения"
                         placeholder="Введите размер вознаграждения"
                         min="0"
                         (valueChange)="userManipulationsSource.next()"
>
</app-wl-strict-input-number>
<app-wl-strict-input-number *ngIf="isFreeBetType"
                         class="wl-row"
                         [control]="viewModel.freeBetId"
                         title="FreeBet Id"
                         placeholder="Укажите Id FreeBet для награды"
                         (valueChange)="userManipulationsSource.next()"
>
</app-wl-strict-input-number>

<button class="wl-button-primary" [disabled]="!isValid" (click)="save()">
    Сохранить
</button>
