import {Injectable, OnInit} from '@angular/core';
import {
  GaConfig,
  GaMessagingService,
  isNullOrUndefined,
} from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {
  AuthService,
  LoginForm,
  WlTrackerLogin,
} from 'src/app/modules/autogen/Shared';
import {WlBlockUiService} from 'src/app/modules/shared/services/wl-block-ui-service';

@UntilDestroy()
@Injectable()
export class WlAuthStateService implements OnInit {
  private authStateStorage$: BehaviorSubject<WlTrackerLogin | null> =
    new BehaviorSubject(null);
  private userState$: Observable<WlTrackerLogin>;

  constructor(
    private readonly _authService: AuthService,
    private readonly _blockUI: WlBlockUiService,
    private readonly _message: GaMessagingService
  ) {
    this.checkLocalStorage();
    this.userState$ = this.authStateStorage$
      .asObservable()
      .pipe(untilDestroyed(this));
  }

  ngOnInit(): void {
  }

  public login(login: string, password: string): void {
    const loginForm: LoginForm = new LoginForm();
    loginForm.login = login;
    loginForm.password = password;

    this._blockUI.start();
    this._authService
      .login(loginForm)
      .pipe(
        untilDestroyed(this),
        finalize(() => this._blockUI.stop())
      )
      .subscribe((response) => {
        if (!response.isCorrect) {
          this.setAuthState(null, null);
          this._message.showMessage(
            `Пользователь не найден причина: ${response.error.message}`
          );
          return;
        }
        this.setAuthState(response.result.login, response.result.id);
      });
  }

  public logout(): void {
    this._authService
      .logout()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response.isCorrect) {
          this.setAuthState(null, null);
        }
      });
  }

  public setAuthState(login: string, id: number): void {
    const param: WlTrackerLogin = new WlTrackerLogin();
    param.id = id;
    param.login = login;

    localStorage.clear();
    if (login) {
      localStorage.setItem('login', JSON.stringify({login: login, id: id}));
    }

    this.authStateStorage$.next(param);
  }

  private checkLocalStorage(): void {
    console.log('checkLocalStorage');
    const param: WlTrackerLogin = new WlTrackerLogin();
    if (localStorage.length === 0) return;
    if (isNullOrUndefined(localStorage.getItem('login'))) return;
    if (localStorage.getItem('login').length === 0) return;
    param.id = JSON.parse(localStorage.getItem('login'))['id'];
    param.login = JSON.parse(localStorage.getItem('login'))['login'];
    this.setAuthState(param.login, param.id);
  }

  get userState(): Observable<WlTrackerLogin> {
    return this.userState$;
  }
}
