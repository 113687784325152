<div class="wl-item-row" *ngIf="isByCountType">
    <div class="wl-item-container">
        <app-wl-strict-input-number class="wl-number-component"
                                 title="Количество ставок"
                                 (change)="change.next()"
                                 placeholder=""
                                 min="1"
                                 [control]="viewModel.collectExpressViewModel.betsCount">
        </app-wl-strict-input-number>
    </div>
</div>
<div class="wl-item-row" *ngIf="isWinType && isBySumType">
    <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                        [title]="'Общая сумма выигрыша'"
                                        [allowedConditionalType]="moreEqualStrictConditionalType"
                                        (change)="change.next()"
                                        minValue="1"
                                        [viewModel]="viewModel.collectExpressViewModel.totalWinAmountRange">
    </app-wl-battle-pass-range-condition>
</div>
<div class="wl-item-row">
    <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                        [title]="'Событий в экспрессе'"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        (change)="change.next()"
                                        minValue="1"
                                        [viewModel]="viewModel.collectExpressViewModel.eventCountExpressRange">
    </app-wl-battle-pass-range-condition>
</div>
<div class="wl-item-row" *ngIf="isBetType || isWinType">
    <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                        [title]="'Коэффициент ставки'"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [minValue]="1.01"
                                        (change)="change.next()"
                                        [viewModel]="viewModel.collectExpressViewModel.betRateRange">
    </app-wl-battle-pass-range-condition>
</div>
<div class="wl-item-row" *ngIf="isByCountType">
    <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                        [title]="'Сумма ставки'"
                                        (change)="change.next()"
                                        minValue="1"
                                        [viewModel]="viewModel.collectExpressViewModel.betSumRange">
    </app-wl-battle-pass-range-condition>
</div>
<div class="wl-item-row" *ngIf="isWinType && isByCountType">
    <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                        [title]="'Сумма выигрыша ставки'"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        (change)="change.next()"
                                        minValue="1"
                                        [viewModel]="viewModel.collectExpressViewModel.betWinAmountRange">
    </app-wl-battle-pass-range-condition>
</div>
