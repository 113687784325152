import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-strict-input-text',
    templateUrl: './strict-input-text.component.html',
    styleUrls: ['./strict-input-text.component.scss'],
})
export class StrictInputTextComponent implements OnInit, AfterViewInit {
    private _isActiveTextArea = false;
    public _activeStateObs = new Subject<boolean>();
    @ViewChild('inputText', { read: ElementRef })
    private inputText: ElementRef;

    @Input() public isSearch: boolean = false;
    @Input()
    public control: StrictFormControl<string>;
    @Input()
    public type = 'text';
    @Input()
    public placeholder = '';
    @Input()
    public title = '';
    @Input()
    public className = '';
    @Output()
    public readonly valueChange = new EventEmitter<void>();
    @Output()
    public elementRef;
    @Output()
    public searchMethod = new EventEmitter<void>();
    @Output()
    public activeStateObs = this._activeStateObs.asObservable();
    constructor() {}

    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        filter((value) => !isNullOrUndefined(value) && this.elementRef.nativeElement.contains(value.target) !== this._isActiveTextArea),
        map((event) => {
            this._isActiveTextArea = this.elementRef.nativeElement.contains(event.target);
            return this._isActiveTextArea;
        })
    );
    ngOnInit(): void {
        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe((value) => this._activeStateObs.next(value));
    }

    public ngAfterViewInit(): void {
        this.elementRef = this.inputText;
    }
}
