import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { IPagingViewModel } from '@koddington/ga-common';
import { WlBattlePassStates, WlPromoTypes } from '../../../../autogen/DailyTasks';

export class WlDtPromotionsSearchViewModel implements IPagingViewModel {
    public term: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public crmTaskGroupId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public promoType: StrictFormControl<WlPromoTypes> = StrictFormControlBuilder.buildNullNumber();
    public state: StrictFormControl<WlBattlePassStates> = StrictFormControlBuilder.buildNullNumber();

    public count: StrictFormControl<number> = new StrictFormControl<number>(30);
    public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
}
