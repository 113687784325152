import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WlRateRaceViewModel } from '../../models/wl-rate-race-view-model';
import { filter, finalize, take } from 'rxjs/operators';
import { WlRateRaceGradeViewModel } from '../../models/wl-rate-race-grade-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as dayjs from 'dayjs';
import { WlBattlePassSeasonAutocompleteStrategy } from '../../../battle-pass/strategies/wl-battle-pass-season-autocomplete-strategy';
import { WlRateRaceCrudValidator } from './validators/wl-rate-race-crud-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlBattlePassRateRaceLootBoxesAutocompleteStrategy } from '../../../battle-pass/strategies/wl-battle-pass-rate-race-loot-boxes-autocomplete-strategy';
import { WlBattlePassRateRacesLootBoxesScrollServiceAdapter } from '../../../battle-pass/services/wrapper-service/wl-battle-pass-rate-races-loot-boxes-scroll-service-adapter';
import { GaMessagingService, GaStrictModelFactory, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { BattlePassRateRaceService, BattlePassService, WlBattlePassStates, WlRateRaceTournamentCreateForm,
    WlRateRaceTournamentGradeForm, WlRateRaceTournamentUpdateForm, WlSeasonListModel } from 'src/app/modules/autogen/BattlePass';
import gaMap = GaStrictModelFactory.gaMap;
import MapExpression = GaStrictModelFactory.MapExpression;
import KeyMap = GaStrictModelFactory.KeyMap;

@UntilDestroy()
@Component({
    selector: 'app-wl-rate-race-crud',
    templateUrl: './wl-rate-race-crud.component.html',
    styleUrls: ['./wl-rate-race-crud.component.scss'],
    providers: [WlBattlePassSeasonAutocompleteStrategy, WlBattlePassRateRaceLootBoxesAutocompleteStrategy]
})
export class WlRateRaceCrudComponent implements OnInit, AfterViewChecked {

    public viewModel: WlRateRaceViewModel;
    public isActiveRateRace = false;

    public readonly userManipulationsSource = new Subject<void>();
    public loading = true;
    public errors: StrictError[] = [];

    private validator = new WlRateRaceCrudValidator();

    constructor(private readonly _rateRaceService: BattlePassRateRaceService,
                private readonly _battlePassService: BattlePassService,
                private readonly _messaging: GaMessagingService,
                private readonly _route: ActivatedRoute,
                private readonly _router: Router,
                private readonly _lootBoxStrategyApiClient: WlBattlePassRateRacesLootBoxesScrollServiceAdapter,
                private readonly _changeDetector: ChangeDetectorRef,
                public seasonAutoCompleteStrategy: WlBattlePassSeasonAutocompleteStrategy) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public ngAfterViewChecked() {
        this._changeDetector.detectChanges();
    }


    public addGrade(): void {
        this.viewModel.gradeDrops.strictValue.push(new WlRateRaceGradeViewModel(this._lootBoxStrategyApiClient));
        this.userManipulationsSource.next();
    }

    public deleteGrade(gradeIndex: number): void {
        this.viewModel.gradeDrops.strictValue.splice(gradeIndex, 1);
        this.userManipulationsSource.next();
    }

    public save(): void {
        this.errors = this.validator.validate(this.viewModel);
        if (!this.isValid) {
            return;
        }

        if (this.viewModel.id.hasStrictValue) {
            this.update();
            return;
        }

        this.create();
    }

    private init(): void {
        this.viewModel = new WlRateRaceViewModel(this._lootBoxStrategyApiClient);
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        if (!this.viewModel.id.hasStrictValue) {
            this.loading = false;
            this.subscriptionToValidate();
            this.userManipulationsSource.next();

            return;
        }

        this._rateRaceService
            .getForUpdate(this.viewModel.id.value)
            .pipe(
                take(1),
                filter((value) => {
                    const hasError = !this._messaging.tryShowError(value);
                    if (!hasError) {
                        this.showRateRaceList();
                    }

                    return hasError;
                }),
                finalize(() => this.loading = false)
            )
            .subscribe((result) => {
                this.loadModel(result.result);
                this.subscriptionToValidate();
                this.userManipulationsSource.next();
            });
    }

    private subscriptionToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.userManipulationsSource.next();
    }

    private loadModel(model: WlRateRaceTournamentUpdateForm): void {
        GaStrictModelFactory.fromModelToStrict(this.viewModel, model, [{
            sourceMapProperty: 'gradeDrops',
            targetMapProperty: 'gradeDrops',
            mapObject: gaMap(model.gradeDrops, (value) => {
                const mapped = new WlRateRaceGradeViewModel(this._lootBoxStrategyApiClient);
                GaStrictModelFactory.fromModelToStrict(mapped, value, null, null);
                return mapped;
            }),
        }], [
            {
                sourceKey: 'startDate',
                targetKey: 'startDate',
                newValue: dayjs(model.startDate)
            }
        ]);

        const lootBoxIds = this.viewModel.gradeDrops.strictValue.map(u => u.rateRaceLootBoxId.strictValue);
        this._rateRaceService.lootBoxesByIds(lootBoxIds)
            .pipe(
                filter(res => !this._messaging.tryShowError(res)),
                take(1)
            )
            .subscribe(res => {
                const mappedLootBox = {};
                res.result.forEach(u => mappedLootBox[u.id] = u);

                this.viewModel.gradeDrops.strictValue
                    .filter(u => mappedLootBox.hasOwnProperty(u.rateRaceLootBoxId.strictValue))
                    .forEach(u => u.rateRaceLootBox.strictValue = mappedLootBox[u.rateRaceLootBoxId.strictValue]);
            });

        if (model.isActivated) {
            this.disableFieldsForActiveRateRace();
        }

        if (isNullOrUndefined(model.battlePassSeasonId )) {
            return;
        }

        this.viewModel.battlePassSeason.strictValue = new WlSeasonListModel({id: model.battlePassSeasonId});

        this._battlePassService.getSeasonLiteById(model.battlePassSeasonId)
            .pipe(
                filter(res => !this._messaging.tryShowError(res)),
                take(1)
            )
            .subscribe(res => {
                this.viewModel.battlePassSeason.strictValue = res.result;
                if (res.result.state === WlBattlePassStates.Finished)
                    this.viewModel.gradeDrops.strictValue.forEach(u => u.isAccrualLootBox.disable());
            });
    }

    private create(): void {
        const form = GaStrictModelFactory.fromStrictToModel(
            WlRateRaceTournamentCreateForm,
            this.viewModel,
            [this.mapGradeModelsToForms()],
            [this.mapSeason()]
        );
        this._rateRaceService
            .create(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                take(1)
            )
            .subscribe(_ => this.showRateRaceList());
    }

    private update(): void {
        const form = GaStrictModelFactory.fromStrictToModel(
            WlRateRaceTournamentUpdateForm,
            this.viewModel,
            [this.mapGradeModelsToForms()],
            [this.mapSeason()]
        );
        this._rateRaceService
            .update(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                take(1)
            )
            .subscribe(_ => this.showRateRaceList());
    }

    private showRateRaceList(): void {
        this._router.navigate(['/menu/raterace/list']);
    }

    private mapGradeModelsToForms(): MapExpression {
        return {
            sourceMapProperty: 'gradeDrops',
            targetMapProperty: 'gradeDrops',
            mapObject: gaMap(
                this.viewModel.gradeDrops.strictValue,
                (value) => GaStrictModelFactory.fromStrictToModel(
                    WlRateRaceTournamentGradeForm,
                    value,
                    null,
                    [{
                        sourceKey: 'rateRaceLootBox',
                        targetKey: 'rateRaceLootBoxId',
                        newValue: !isNullOrUndefined(value.rateRaceLootBox?.strictValue?.id) ? value.rateRaceLootBox?.strictValue?.id : null
                    }]
                )
            ),
        };
    }


    private mapSeason(): KeyMap {
        return {
            sourceKey: 'battlePassSeason',
            targetKey: 'battlePassSeasonId',
            newValue: !isNullOrUndefined(this.viewModel.battlePassSeason.strictValue?.id) ? this.viewModel.battlePassSeason.strictValue.id : null
        };
    }

    private disableFieldsForActiveRateRace(): void {
        this.viewModel.name.disable();
        this.viewModel.startDate.disable();
        this.viewModel.prizePlacesCount.disable();
        this.viewModel.battlePassSeason.disable();
        this.viewModel.gradeDrops.disable();
        this.viewModel.gradeDrops.strictValue.forEach(u => {
            u.minAmount.disable();
            u.rateRaceLootBox.disable();
        });

        this.isActiveRateRace = true;
    }

    get purpose(): string {
        return this.viewModel.id.hasStrictValue
            ? `Редактирование турнира RateRace №${this.viewModel.id.strictValue}`
            : 'Создание турнира RateRace';
    }

    get saveButtonText(): string {
        return this.viewModel.id.hasStrictValue
            ? 'Сохранить турнир RateRace'
            : 'Создать турнир RateRace';
    }

    get canDeleteGrade(): boolean {
        return !this.isActiveRateRace && this.viewModel.gradeDrops.strictValue.length > 1;
    }

    get canAddGrade(): boolean {
        return !this.isActiveRateRace && this.viewModel.gradeDrops.strictValue.length < 5;
    }


    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get gradeErrors(): Observable<StrictError[]> {
        return this.viewModel?.gradeDrops?.strictErrors;
    }
}
