import { Component, OnInit } from '@angular/core';
import { BattlePassService, WlGameTaskSearchForm, WlTaskListModel } from 'src/app/modules/autogen/BattlePass';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { Router } from '@angular/router';
import { GaTableData } from '@koddington/ga-common';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlBattlePassGameTaskSearchModel } from './models/wl-battle-pass-game-task-search-model';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-game-task-list',
    templateUrl: './wl-battle-pass-game-task-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassGameTaskListComponent implements OnInit {
    public result: GaPagedResult<WlTaskListModel>;
    public tableData: GaTableData<WlTaskListModel>;
    public viewSearchModel: WlBattlePassGameTaskSearchModel = new WlBattlePassGameTaskSearchModel();

    constructor(
        private readonly _battlePassService: BattlePassService,
        private readonly _router: Router,
        private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlTaskListModel>>
    ) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    public pageFiltered(page: GaPagingForm = null): void {
        this._searchManagementState.nextSearch(page, true);
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/gameTask/edit']);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._battlePassService.listGameTask(form as WlGameTaskSearchForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                {title: 'Код', widthSize: 100}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 500})
            .addSimpleColumn((elem) => elem.description, {title: 'Описание', widthSize: 590})
            .addSimpleColumn((elem) => elem.seasonId, {title: 'Id сезона', widthSize: 100})
            .addSimpleColumn((elem) => elem.levelId, {title: 'Id уровня', widthSize: 100})
            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
