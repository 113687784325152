import { IScrollService } from '../../../../common/abstracts/types/scroll-service-interface';
import {
    BattlePassRewardsService,
    WlBattlePassRewardModel,
    WlBattlePassRewardSearchForm,
} from '../../../autogen/BattlePass';
import { Observable } from 'rxjs';
import { WlResult } from '../../../../models/common/results/wl-result';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassRewardsScrollServiceAdapter implements IScrollService<WlBattlePassRewardModel, WlBattlePassRewardSearchForm> {
    constructor(private _service: BattlePassRewardsService) {
    }

    public search(item: WlBattlePassRewardSearchForm): Observable<WlResult<WlBattlePassRewardModel[]>> {
        return this._service.list(item).pipe(map(u => {
            const res = new WlResult<WlBattlePassRewardModel[]>();
            res.result = u.results;
            return res;
        }));
    }
}
