import {Component, OnInit} from '@angular/core';
import {WlSeasonViewModel} from '../../models/wl-season-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Observable, Subject} from 'rxjs';
import {filter, finalize, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import {WlBattlePassSeasonCrudValidator} from './validator/wl-battle-pass-season-crud-validator';
import {
    WlBattlePassLevelCrmTaskValidator
} from '../wl-battle-pass-level-edit/validator/wl-battle-pass-level-crm-task-validator';
import {
    WlBattlePassLevelGameTaskValidator
} from '../wl-battle-pass-level-edit/validator/wl-battle-pass-level-game-task-validator';
import {WlBattlePassLevelEditComponent} from '../wl-battle-pass-level-edit/wl-battle-pass-level-edit.component';
import {Dayjs} from 'dayjs';
import { WlBattlePassRateRacesAutocompleteStrategy } from '../../strategies/wl-battle-pass-rate-races-autocomplete-strategy';
import { GaConfirmationService, GaMessagingService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import {
    BattlePassService,
    WlBattlePassStates,
    WlLevelCrmTaskForm, WlLevelFreeBetLootBoxForm, WlLevelGameLootBoxForm, WlLevelGameTaskForm,
    WlSeasonCreateForm,
    WlSeasonLevelForm,
    WlSeasonLevelModel,
    WlSeasonModel,
    WlSeasonUpdateForm
} from '../../../autogen/BattlePass';
import { GaStrictModelFactory } from '@koddington/ga-common';
import { WlDialogService } from 'src/app/modules/shared/services/wl-dialog.service';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-season-crud',
    templateUrl: './wl-battle-pass-season-crud.component.html',
    styleUrls: ['./wl-battle-pass-season-crud.component.scss'],
    providers: [WlBattlePassLevelCrmTaskValidator, WlBattlePassLevelGameTaskValidator, WlBattlePassRateRacesAutocompleteStrategy],
})
export class WlBattlePassSeasonCrudComponent implements OnInit {
    public viewModel: WlSeasonViewModel = new WlSeasonViewModel();
    public loading: boolean;
    public canUpdate: boolean;
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    public seasonEndDate: Dayjs = null;

    private changeableTasksOnActiveLevel = new Map<number, number[]>();
    private readonly minLevelsCount = 1;
    private readonly maxLevelsCount = 60;
    private readonly validator = new WlBattlePassSeasonCrudValidator(this.minLevelsCount, this.maxLevelsCount);

    constructor(
        private readonly _messaging: GaMessagingService,
        private readonly _confirmation: GaConfirmationService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _titleService: Title,
        private readonly _battlePassService: BattlePassService,
        private readonly _dialogs: WlDialogService,
        public readonly rateRaceStrategy: WlBattlePassRateRacesAutocompleteStrategy
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        this.load();
        this._titleService.setTitle('Создание / Редактирование сезона');
    }

    public save(): void {
        this.loading = true;
        this.errors = this.validator.validate(this.viewModel);
        if (this.errors.length > 0) {
            this.loading = false;
            return;
        }

        if (!this.isLevelsValid) {
            this._messaging.showMessage('Сезон не может содержать уровени без даты старта между уровнями с датой старта');
            this.loading = false;
            return;
        }

        if (this.viewModel.id.hasStrictValue) {
            this.confirmationUpdate();
        } else {
            this.create();
        }
    }

    public addNewLevel(index?: number): void {
        this._dialogs
            .openDialog(WlBattlePassLevelEditComponent, {season: this.viewModel})
            .pipe(filter((resp) => !!resp))
            .subscribe((res) => {
                this.viewModel.levels.strictValue.push(res);
                this.sortLevels();
                this.userManipulationsSource.next();
            });
    }

    public deleteLevel(index: number) {
        this.viewModel.levels.strictValue = this.viewModel.levels.strictValue.filter((u, k) => k !== index);
        this.userManipulationsSource.next();
    }

    public editLevel(index: number): void {
        this._dialogs
            .openDialog(WlBattlePassLevelEditComponent, {season: this.viewModel, index: index, changeableTasksOrders: this.changeableTasksOnActiveLevel[index]})
            .pipe(filter((resp) => !!resp))
            .subscribe((res: {level: WlSeasonLevelModel, changeableTasksOrders: number[]}) => {
                this.viewModel.levels.strictValue = this.viewModel.levels.strictValue.filter((_, k) => k !== index);
                this.viewModel.levels.strictValue.push(res.level);
                this.changeableTasksOnActiveLevel[index] = res.changeableTasksOrders;
                this.sortLevels();
                this.userManipulationsSource.next();
            });
    }

    private subscriptionToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
    }

    private create(): void {
        const form = this.createAddForm();

        this._battlePassService
            .addSeason(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => (this.loading = false)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.showListSeasons();
            });
    }

    private update(): void {
        const updateForm = this.createUpdateForm();

        this._battlePassService
            .updateSeason(updateForm)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => this.loading = false),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.showListSeasons();
            });
    }

    private load(): void {
        if (this.viewModel.id.hasStrictValue) {
            this._battlePassService
                .getSeasonById(this.viewModel.id.value)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this)
                )
                .subscribe((result) => {
                    this.loadModel(result.result);
                    this.seasonEndDate = dayjs(result.result.endDate);
                    this.loading = false;
                    this.canUpdate = result.result.state === WlBattlePassStates.NotStarted;
                    this.setControlsState();
                    this.subscriptionToValidate();
                });
        } else {
            this.loading = false;
            this.canUpdate = true;
            this.subscriptionToValidate();
        }
        this.userManipulationsSource.next();
    }

    private setControlsState(): void {
        if (!this.canUpdate) {
            this.viewModel.startDate.disable();
            this.viewModel.levelsCount.disable();
            this.viewModel.name.disable({emitEvent: false});
            if (this.viewModel.state.strictValue === WlBattlePassStates.Finished) {
                this.viewModel.endDate.disable();
            }
            if (this.viewModel.payoutDate.hasStrictValue && this.viewModel.payoutDate.strictValue.unix() < dayjs().unix()) {
                this.viewModel.payoutDate.disable();
            }
        }
    }

    private loadModel(model: WlSeasonModel): void {
        GaStrictModelFactory.fromModelToStrict(this.viewModel, model, null,
            [
                {
                    sourceKey: 'payoutDate',
                    targetKey: 'payoutDate',
                    newValue: !isNullOrUndefined(model.payoutDate) ? dayjs(model.payoutDate) : null
                },
                {sourceKey: 'startDate', targetKey: 'startDate', newValue: dayjs(model.startDate)},
                {sourceKey: 'endDate', targetKey: 'endDate', newValue: dayjs(model.endDate)}
            ],
            'startJobId', 'endJobId');
    }

    private createAddForm(): WlSeasonCreateForm {
        const form = new WlSeasonCreateForm();

        form.name = this.viewModel.name.strictValue;
        form.description = this.viewModel.description.strictValue;
        form.imageUrl = this.viewModel.imageUrl.strictValue;
        form.payoutDate = this.viewModel.payoutDate.strictValue;
        form.startDate = this.viewModel.startDate.strictValue;
        form.endDate = this.viewModel.endDate.strictValue;
        form.levelsCount = this.viewModel.levelsCount.strictValue;
        form.levels = this.mappedLevelModel(this.viewModel.levels.strictValue);

        if (this.viewModel.rateRace.hasStrictValue) {
            form.rateRace = this.viewModel.rateRace.strictValue.id;
        }

        return form;
    }

    private createUpdateForm(): WlSeasonUpdateForm {
        const form = new WlSeasonUpdateForm(this.createAddForm());
        form.id = this.viewModel.id.strictValue;

        return form;
    }

    private mappedLevelModel(models: WlSeasonLevelModel[]): WlSeasonLevelForm[] {
        const mapped: WlSeasonLevelForm[] = [];

        models.forEach((item) => {
            const level = new WlSeasonLevelForm();

            level.id = item.id;
            level.order = item.order;
            level.name = item.name;
            level.description = item.description;
            level.imageUrl = item.imageUrl;
            level.iconUrl = item.iconUrl;
            level.inactiveIconUrl = item.inactiveIconUrl;
            level.levelStartDate = item.startDate;

            level.crmTasks = item.crmTaskGroups.filter(val => !isNullOrUndefined(val.taskGroup)).map((val) => {
                const crmTask = new WlLevelCrmTaskForm();
                crmTask.order = val.order;
                crmTask.taskId = val.taskGroup.id;
                crmTask.isWildCard = val.taskGroup.isWildCard;

                return crmTask;
            });

            level.gameTasks = item.gameTasks.filter(val => !isNullOrUndefined(val.task)).map((val) => {
                const gameTask = new WlLevelGameTaskForm();
                gameTask.order = val.order;
                gameTask.taskId = val.task.id;
                gameTask.isWildCard = val.task.isWildCard;

                return gameTask;
            });

            level.gameLootBoxes = item.gameLootBoxes.filter(val => !isNullOrUndefined(val.id)).map(val => {
                const gameLootBoxForm = new WlLevelGameLootBoxForm();
                gameLootBoxForm.lootBoxId = val.id;
                gameLootBoxForm.completeTaskType = val.completeTaskType;

                return gameLootBoxForm;
            });

            level.freeBetLootBoxes = item.freeBetLootBoxes.filter(val => !isNullOrUndefined(val.id)).map(val => {
                const gameLootBoxForm = new WlLevelFreeBetLootBoxForm();
                gameLootBoxForm.lootBoxId = val.id;
                gameLootBoxForm.completeTaskType = val.completeTaskType;

                return gameLootBoxForm;
            });

            level.isGamblerLevel = item.isGamblerLevel;

            if (!level.isGamblerLevel)
                level.templateId = item.template.id;

            mapped.push(level);
        });

        return mapped;
    }

    private sortLevels(): void {
        this.viewModel.levels.strictValue = this.viewModel.levels.strictValue.sort((a, b) => a.order - b.order);
    }

    private showListSeasons(): void {
        this._router.navigate(['/menu/battlePass/season']);
    }

    private confirmationUpdate(): void {
        if (this.viewModel.endDate.strictValue.unix() === this.seasonEndDate.unix()) {
            this.update();
            return;
        }

        this._confirmation
            .openDialog('Изменена дата технического завершения сезона. Подтвердить изменения?', 'Подтвердить')
            .pipe(
                take(1),
                untilDestroyed(this),
                finalize(() => this.loading = false)
            )
            .subscribe((isAccepted) => {
                if (isAccepted === true) {
                    this.update();
                }
            });
    }

    get purpose(): string {
        if (this.viewModel.id.hasStrictValue) {
            return `Редактирование сезона для боевого пропуска № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание сезона для боевого пропуска';
    }

    get saveButtonText(): string {
        if (this.viewModel.id.hasStrictValue) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    get canSave(): boolean {
        return !this.loading && this.errors.length === 0;
    }

    get canDelete(): boolean {
        return true;
    }

    get countLevel(): number {
        return this.viewModel.levels.strictValue.length;
    }

    get canAddNewLevel(): boolean {
        return this.canUpdate && this.viewModel.levels.strictValue.length < Math.min(this.viewModel.levelsCount.strictValue, this.maxLevelsCount);
    }

    get isUpdate(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get levels(): WlSeasonLevelModel[] {
        return this.viewModel.levels.strictValue;
    }

    get levelsErrors(): Observable<StrictError[]> {
        return this.viewModel.levels.strictErrors;
    }

    get isLevelsValid(): boolean {
        this.sortLevels();
        const sortedLevels = this.viewModel.levels.strictValue;
        if (isNullOrUndefined(sortedLevels)) {
            return true;
        }

        const firstLevel = sortedLevels[0];
        if (isNullOrUndefined(firstLevel.startDate)) {
            const isStartDateExist = this.viewModel.levels.strictValue.some(u => !isNullOrUndefined(u.startDate));
            if (isStartDateExist) {
                return false;
            }
        }

        if (!isNullOrUndefined(firstLevel.startDate)) {
            const reversedLevels = sortedLevels.reverse();
            const lastLevelWithDateIndex = reversedLevels.findIndex(u => !isNullOrUndefined(u.startDate));
            const lastLevelWithDate = reversedLevels[lastLevelWithDateIndex];

            const incorrectLevelsExist = sortedLevels.some(u => u.order > firstLevel.order
                && u.order < lastLevelWithDate.order && isNullOrUndefined(u.startDate));

            this.sortLevels();
            if (incorrectLevelsExist) {
                return false;
            }
        }

        return true;
    }
}
