import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WlTaskComparisonConditionsTypeStrategy } from '../../strategies/wl-task-comparison-conditions-type-strategy';
import { WlRangeConditionViewModel } from '../../models/wl-range-condition-view-model';
import { StrictFormControl } from '@koddington/ga-common';
import { WlTaskComparisonConditionsTypes } from '../../../autogen/BattlePass';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-wl-battle-pass-range-condition',
  templateUrl: './wl-battle-pass-range-condition.component.html',
  styleUrls: ['./wl-battle-pass-range-condition.component.scss']
})
export class WlBattlePassRangeConditionComponent implements OnInit, OnDestroy {

    @Input()
    public viewModel: StrictFormControl<WlRangeConditionViewModel>;
    @Input()
    public title: string;
    @Input()
    public allowedConditionalType: WlTaskComparisonConditionsTypes[];
    @Input()
    public minValue: number;
    @Input()
    public step: number;

    @Output()
    public change = new EventEmitter<void>();
    public conditionsTypeStrategy: WlTaskComparisonConditionsTypeStrategy;

    public showRange = false;

    constructor() { }

    ngOnInit(): void {
        this.conditionsTypeStrategy = new WlTaskComparisonConditionsTypeStrategy();
        if (!!this.allowedConditionalType) {
            this.conditionsTypeStrategy.setFilterType(this.allowedConditionalType);
        }

        this.refreshShowRange();
        this.viewModel.strictValue.condition.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.refreshShowRange());
    }

    public ngOnDestroy(): void { }

    private refreshShowRange() {
        this.showRange = this.viewModel.strictValue.condition.hasStrictValue && this.viewModel.strictValue.condition.strictValue === WlTaskComparisonConditionsTypes.Range;
    }
}
