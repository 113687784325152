import { Injectable, OnDestroy } from '@angular/core';
import { WlBattlePassTaskTypes, WlBattlePassTaskTypesExtensions } from '../../autogen/BattlePass';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnumExtensions, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownItem } from '../../../models/common/enum-dropdown/dropdown-item';

@UntilDestroy()
@Injectable()
export class WlCrmTaskTaskTypeStrategy implements IDropdownStrategy<WlBattlePassTaskTypes>, OnDestroy {
    private readonly fnIgnoreRules: () => WlBattlePassTaskTypes[] | null;
    private source$ = new BehaviorSubject<Array<WlBattlePassTaskTypes>>([]);

    constructor(private readonly ignoreEntities: () => WlBattlePassTaskTypes[] | null) {
        this.fnIgnoreRules = ignoreEntities;

        this.updateEntities();
    }

    public ngOnDestroy(): void {
        this.source$.unsubscribe();
    }

    public updateEntities(): void {
        let entities = EnumExtensions.toArray(WlBattlePassTaskTypes);
        if (isNullOrUndefined(this.fnIgnoreRules)) {
            this.source$.next(entities);
            return;
        }

        const arrayTaskTypesForIgnore = this.fnIgnoreRules();
        if (!isNullOrUndefined(arrayTaskTypesForIgnore) && arrayTaskTypesForIgnore.length > 0) {
            entities = entities.filter((u) => !arrayTaskTypesForIgnore.includes(u));
        }
        this.source$.next(entities);
    }

    getEntities(): Observable<Array<WlBattlePassTaskTypes>> {
        return this.source$.asObservable().pipe(untilDestroyed(this));
    }

    map: (model: WlBattlePassTaskTypes) => DropdownItem<WlBattlePassTaskTypes> = model => {
        const item = new DropdownItem<WlBattlePassTaskTypes>();
        item.entity = model;
        item.title = WlBattlePassTaskTypesExtensions.format(model);
        item.id = model.toString();

        return item;
    }
}
