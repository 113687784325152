import { Routes } from '@angular/router';
import { WlRateRaceLootBoxListComponent } from './components/wl-lootbox-list/wl-rate-race-lootbox-list.component';
import { WlRateRaceLootboxCrudComponent } from './components/wl-lootbox-crud/wl-rate-race-lootbox-crud.component';
import { WlRateRaceNicknameModerationComponent } from './components/wl-nickname-moderation/wl-rate-race-nickname-moderation.component';
import { WlRateRaceCrudComponent } from './components/wl-rate-race-crud/wl-rate-race-crud.component';
import { WlRateRaceListComponent } from './components/wl-rate-race-list/wl-rate-race-list.component';

export const RateRaceRoutes: Routes = [
    {
        path: 'raterace',
        data: {
            routeName: 'RateRace',
        },
        children: [
            {
                path: 'rate-race-nickname-moderation',
                data: {
                    highlightMenuItem: 'rate-race-nickname-moderation',
                    routeName: 'Модерация никнеймов',
                },
                component: WlRateRaceNicknameModerationComponent,
            },
            {
                path: 'create',
                data: {
                    highlightMenuItem: 'rate-race-create',
                    routeName: 'Создание турнира RateRace',
                },
                component: WlRateRaceCrudComponent,
            },
            {
                path: 'update/:id',
                data: {
                    highlightMenuItem: 'rate-race-create',
                    routeName: 'Изменение турнира RateRace',
                },
                component: WlRateRaceCrudComponent,
            },
            {
                path: 'list',
                data: {
                    highlightMenuItem: 'rate-race-list',
                    routeName: 'Список турниров RateRace',
                },
                component: WlRateRaceListComponent,
            },
            {
                path: 'lootBox',
                data: {
                    routeName: 'Лутбоксы',
                },
                children: [
                    {
                        path: 'list',
                        data: {
                            highlightMenuItem: 'rate-race-lootbox',
                            routeName: 'Лутбоксы',
                        },
                        component: WlRateRaceLootBoxListComponent,
                    },
                    {
                        path: 'edit',
                        component: WlRateRaceLootboxCrudComponent,
                        data: {
                            highlightMenuItem: 'rate-race-lootbox',
                            routeName: 'Редактирование лутбокса',
                        },
                    },
                    {
                        path: 'edit/:id',
                        component: WlRateRaceLootboxCrudComponent,
                        data: {
                            highlightMenuItem: 'rate-race-lootbox',
                            routeName: 'Редактирование лутбокса',
                        },
                    },
                ],
            },
        ],
    },
];
