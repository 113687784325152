import { WlBattlePassFreeBetLootBoxContentsViewModel } from './view-models/wl-battle-pass-free-bet-loot-box-contents-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlBattlePassFreeBetLootBoxCrudValidator } from './validators/wl-battle-pass-free-bet-loot-box-crud-validator';
import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { WlBattlePassFreeBetLootBoxCrudViewModel } from './view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';
import { Observable, Subject } from 'rxjs';
import { GaMessagingService, GaPagingForm, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { ActivatedRoute, Router } from '@angular/router';
import { BattlePassFreeBetLootBoxesService, WlFreeBetLootBoxCreateForm, WlFreeBetLootBoxUpdateForm } from 'src/app/modules/autogen/BattlePass';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';
import { finalize, filter } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { GaTableCellTemplate } from '@koddington/ga-common';
import { GaTableData } from '@koddington/ga-common';
import {WlBattlePassFreeBetLootBoxMappers} from './tools/wl-battle-pass-free-bet-loot-box.mappers';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-free-bet-loot-box-crud',
    templateUrl: './wl-battle-pass-free-bet-loot-box-crud.component.html',
    styleUrls: ['./wl-battle-pass-free-bet-loot-box-crud.component.scss'],
})
export class WlBattlePassFreeBetLootBoxCrudComponent implements OnInit, AfterContentInit {
    @Input()
    public asSubComponent: boolean = false;

    @Input()
    public viewModel: WlBattlePassFreeBetLootBoxCrudViewModel;

    @Input()
    public outsideUpdateEmitter: EventEmitter<void>;

    @Output()
    public updateValuesEmitter: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public emptyContentEnrichFunc: EventEmitter<WlBattlePassFreeBetLootBoxContentsViewModel> = new EventEmitter<WlBattlePassFreeBetLootBoxContentsViewModel>();

    @ViewChild('freeBetAmount', {static: true}) freeBetAmount: TemplateRef<any>;
    @ViewChild('freeBetProbability', {static: true}) freeBetProbability: TemplateRef<any>;
    @ViewChild('scarcity', {static: true}) scarcity: TemplateRef<any>;
    @ViewChild('scarValue', {static: true}) scarValue: TemplateRef<any>;
    @ViewChild('imageUrl', {static: true}) imageUrl: TemplateRef<any>;
    @ViewChild('actionButtons', {static: true}) actionButtons: TemplateRef<any>;

    public loading = true;
    public tableData = new GaTableData<WlBattlePassFreeBetLootBoxContentsViewModel>();
    public userManipulationsSource = new Subject<void>();
    public lootBoxContentsManipulationsSource = new Subject<void>();

    private errors: StrictError[] = [];
    private _validator = new WlBattlePassFreeBetLootBoxCrudValidator();

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _messaging: GaMessagingService,
        private readonly _router: Router,
        private readonly _service: BattlePassFreeBetLootBoxesService
    ) {}

    ngAfterContentInit(): void {
        this.forceMarkComponentChanged();
    }

    ngOnInit() {
        this.init();
    }

    public save(): void {
        if (this.isEdit) {
            this.update();
            return;
        }

        this.createNew();
    }

    public copy() {
        const form = this.getCreateForm();
        this.addLootBox(form).subscribe((res) => {
            this._messaging.showMessage('Копия лутбокса создана и открыта.');
            RouterNavigationExtensions.navigateAndRefresh(this._router, ['/menu/battlePass/freeBetLootBox/edit', res.result]);
        });
    }

    public asContentModel(rawModel: any) {
        return rawModel as WlBattlePassFreeBetLootBoxContentsViewModel;
    }

    public contentPageChanged(form: GaPagingForm) {
        this.contentsPagingForm = form;
        this.lootBoxContentsManipulationsSource.next();
    }

    public addEmptyContent(): void {
        const content = new WlBattlePassFreeBetLootBoxContentsViewModel();
        this.emptyContentEnrichFunc.emit(content);

        this.lootBoxContents.push(content);
        this.movePagingToLastPage();
        this.forceMarkComponentChanged();
    }

    public deleteContent(content: WlBattlePassFreeBetLootBoxContentsViewModel): void {
        const index = this.lootBoxContents.indexOf(content);
        if (index < 0) {
            return;
        }

        this.lootBoxContents.splice(index, 1);

        if (this.lootBoxContents.length <= this.contentsPagingForm.offset) {
            this.movePagingToLastPage();
        }
        this.forceMarkComponentChanged();
    }

    public forceMarkComponentChanged(): void {
        this.lootBoxContentsManipulationsSource.next();
        this.userManipulationsSource.next();
    }

    private init(): void {
        if (isNullOrUndefined(this.viewModel))
            this.loadViewModelFromScratch();

        this.outsideUpdateEmitter?.pipe(untilDestroyed(this))
            .subscribe(res => this.forceMarkComponentChanged());

        this.loading = false;
        this.subscribeToLootBoxContentsCountChange();
        this.subscribeToUserManipulation();
    }

    private subscribeToLootBoxContentsCountChange(): void {
        this.lootBoxContentsManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.viewModel.pagingResult.total = this.lootBoxContents.length;
            this.refillVisibleLootBoxContents();
            this.updateValuesEmitter.emit();
        });
    }

    private subscribeToUserManipulation(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.viewModel.probabilitiesSum.strictValue = this.lootBoxContents
                .filter((u) => u.probability.hasStrictValue)
                .reduce((acc, curr) => {
                    return Math.round((acc + curr.probability.strictValue) * 10_000_000_000) / 10_000_000_000;
                }, 0);

            this.errors = this._validator.validate(this.viewModel);
            this.updateValuesEmitter.emit();
        });
    }

    private refillVisibleLootBoxContents(): void {
        const getModelForControls = (u: WlBattlePassFreeBetLootBoxContentsViewModel) => {
            return {
                model: u,
            };
        };

        const data = new GaTableData<WlBattlePassFreeBetLootBoxContentsViewModel>();

        const offset = this.contentsPagingForm.offset;
        const resultResponse = this.lootBoxContents.slice(offset, offset + this.viewModel?.pagingResult.paging.count);

        this.tableData = data
            .addTemplateColumn(new GaTableCellTemplate(this.freeBetAmount, getModelForControls), { title: 'Сумма фрибета', widthSize: 200 })
            .addTemplateColumn(new GaTableCellTemplate(this.freeBetProbability, getModelForControls), { title: 'Вероятность фрибета', widthSize: 200 })
            .addTemplateColumn(new GaTableCellTemplate(this.scarcity, getModelForControls), { title: 'Scarcity', widthSize: 300 })
            .addTemplateColumn(new GaTableCellTemplate(this.scarValue, getModelForControls), { title: 'Scar Value', widthSize: 200 })
            .addTemplateColumn(new GaTableCellTemplate(this.imageUrl, getModelForControls), { title: 'Ссылка на изображение', widthSize: 300 })
            .addTemplateColumn(new GaTableCellTemplate(this.actionButtons, getModelForControls), { title: 'Действия', widthSize: 100 })
            .setData(resultResponse);
    }

    private createNew() {
        const form = this.getCreateForm();
        this.addLootBox(form).subscribe((_) => {
            this.navigateToListPage();
        });
    }

    private addLootBox(form: WlFreeBetLootBoxCreateForm): Observable<WlResult<number>> {
        this.loading = true;
        return this._service.create(form).pipe(
            take(1),
            filter((u) => !this._messaging.tryShowError(u)),
            finalize(() => (this.loading = false))
        );
    }

    private update() {
        this.loading = true;
        const form = this.getUpdateForm();
        this._service
            .update(form)
            .pipe(
                take(1),
                filter((u) => !this._messaging.tryShowError(u)),
                finalize(() => (this.loading = false))
            )
            .subscribe((_) => this.navigateToListPage());
    }

    private getCreateForm(): WlFreeBetLootBoxCreateForm {
        return WlBattlePassFreeBetLootBoxMappers.mapLootBoxStrictToForm(WlFreeBetLootBoxCreateForm, this.viewModel);
    }

    private getUpdateForm(): WlFreeBetLootBoxUpdateForm {
        return WlBattlePassFreeBetLootBoxMappers.mapLootBoxStrictToForm(WlFreeBetLootBoxUpdateForm, this.viewModel);
    }

    private navigateToListPage() {
        this._router.navigate(['/menu/battlePass/freeBetLootBox']);
    }

    private movePagingToLastPage(): void {
        this.contentsPagingForm.offset = Math.trunc((this.lootBoxContents.length - 1) / this.contentsPagingForm.count) * this.contentsPagingForm.count;
    }

    get contentsPagingForm(): GaPagingForm {
        return this.viewModel?.pagingResult.paging;
    }

    set contentsPagingForm(form: GaPagingForm) {
        this.viewModel.pagingResult.paging = form;
    }

    get lootBoxContents(): WlBattlePassFreeBetLootBoxContentsViewModel[] {
        return this.viewModel?.lootBoxContents.strictValue;
    }

    get hasLootBoxContents(): boolean {
        return this.lootBoxContents?.length > 0 ?? false;
    }

    get canDeleteLootBoxContents(): boolean {
        return this.lootBoxContents?.length > 1 ?? false;
    }

    get isEdit(): boolean {
        return this.viewModel?.id.hasStrictValue;
    }

    get pageHeaderText(): string {
        return this.isEdit ? `Редактирование фрибетного лутбокса №${this.viewModel?.id.strictValue}` : 'Создание фрибетного лутбокса';
    }

    get saveButtonText(): string {
        return this.isEdit ? 'Сохранить' : 'Создать';
    }

    get canSave(): boolean {
        return this.errors.length === 0 && !this.loading;
    }

    private loadViewModelFromScratch(): void {
        this.viewModel = new WlBattlePassFreeBetLootBoxCrudViewModel();
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        if (!this.isEdit) {
            this.loading = false;
            return;
        }

        this._service
            .getForUpdate(this.viewModel.id.strictValue)
            .pipe(
                take(1),
                filter((u) => !this._messaging.tryShowError(u)),
                finalize(() => (this.loading = false))
            )
            .subscribe((u) => {
                WlBattlePassFreeBetLootBoxMappers.mapLootBoxFormToStrict(this.viewModel, u.result);

                this.lootBoxContentsManipulationsSource.next();
                this.userManipulationsSource.next();
            });
    }
}
