<app-wl-page-header header="Список турниров RateRace"></app-wl-page-header>
<app-wl-loading *ngIf="loading"></app-wl-loading>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить турнир</button>
</div>
<div class="wl-search-container">
    <div class="wl-search-row">
        <div class="wl-search-wrapper">
            <app-wl-strict-input-number
                class="wl-app-input-search-wrapper"
                isSearch="true"
                [control]="pagingViewModel.minPlacesNumber"
                placeholder="Мин.количество мест"
                [min]="0"
                (searchMethod)="pageFiltered()"
            ></app-wl-strict-input-number>
        </div>
        <div class="wl-search-wrapper wl-margin">
            <app-wl-strict-input-number
                class="wl-app-input-search-wrapper"
                isSearch="true"
                [control]="pagingViewModel.maxPlacesNumber"
                placeholder="Макс.количество мест"
                [min]="0"
                (searchMethod)="pageFiltered()"
            ></app-wl-strict-input-number>
        </div>
    </div>
</div>

<ga-table *ngIf="!!tableData" [data]="tableData" [breakWordOnly]="false"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="result" [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
