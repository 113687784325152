import { Component, OnInit } from '@angular/core';
import { WlAuthStateService } from '../services/auth-state-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined } from '@koddington/ga-common';
import { Router } from '@angular/router';
import { AuthValidator } from '../validators/auth-validator';
import { LoginViewModel } from '../view-models/wl-login-view-model';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './app-login-component.html',
    styleUrls: ['./app-login-component.scss'],
})
export class WlLoginComponent implements OnInit {
    public viewModel: LoginViewModel = new LoginViewModel();
    private readonly validator: AuthValidator;

    constructor(
        private readonly _authStateService: WlAuthStateService,
        private readonly _router: Router
    ) {
        this.validator = new AuthValidator();
    }

    ngOnInit(): void {
        this._authStateService.userState
            .pipe(untilDestroyed(this))
            .subscribe((u) => {
                if (!isNullOrUndefined(u?.login)) {
                    this._router.navigate(['menu']);
                }
            });
    }

    public login(): void {
        const error = this.validator.validate(this.viewModel);
        if (error.length) {
            return;
        }

        this._authStateService.login(
            this.viewModel.login.strictValue,
            this.viewModel.password.strictValue
        );
    }
}
