import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GaMessagingService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { BattlePassService, WlGameTask, WlGameTaskCreateForm, WlGameTaskUpdateForm } from 'src/app/modules/autogen/BattlePass';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';
import { WlCrmGameTaskViewModel } from '../../models/wl-crm-game-task-view-model';
import { WlBattlePassGameTaskTemplateAutocompleteStrategy } from '../../strategies/wl-battle-pass-game-task-template-autocomplete.strategy';
import { WlBattlePassGameTaskValidator } from './validator/wl-battle-pass-game-task-validator';

@UntilDestroy()
@Component({
  selector: 'app-wl-battle-pass-game-task-crud',
  templateUrl: './wl-battle-pass-game-task-crud.component.html',
  styleUrls: ['./wl-battle-pass-game-task-crud.component.scss'],
    providers: [WlBattlePassGameTaskTemplateAutocompleteStrategy]
})
export class WlBattlePassGameTaskCrudComponent implements OnInit {
    protected loading: boolean;
    protected viewModel: WlCrmGameTaskViewModel = new WlCrmGameTaskViewModel();
    protected readonly userManipulationsSource = new Subject<void>();
    protected errors: StrictError[] = [];
    private readonly validator = new WlBattlePassGameTaskValidator();
    constructor(private readonly _messaging: GaMessagingService,
                private readonly _router: Router,
                private readonly _route: ActivatedRoute,
                private readonly _titleService: Title,
                private readonly _battlePassService: BattlePassService,
                protected templateStrategy: WlBattlePassGameTaskTemplateAutocompleteStrategy
    ) {}

    ngOnInit(): void {
        this.load();
    }

    public save(): void {
        this.loading = true;
        if (this.isEdit) {
            this.updateTask();
        } else {
            this.addTask();
        }
    }

    public clone(): void {
        this.createByService()
            .subscribe(res => {
                this._messaging.showMessage('Копия создана и открыта.');
                this.showGameTaskByNumber(res.result);
            });
    }

    private updateTask(): void {
        if (!this.isValid) {
            this.loading = false;
            return;
        }

        this.loading = true;
        const form = this.createUpdateForm();
        this._battlePassService.updateGameTask(form).pipe(
            take(1),
            filter((value) => !this._messaging.tryShowError(value)),
            finalize(() => this.loading = false)
        ).subscribe(() => {
            this.showListGameTask();
        });
    }

    private addTask(): void {
        this.createByService().subscribe(() => this.showListGameTask());
    }

    private createByService(): Observable<WlResult<number>> {
        if (!this.isValid) {
            this.loading = false;
            return;
        }

        this.loading = true;
        const form = this.createAddForm();
        return this._battlePassService.addGameTask(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                untilDestroyed(this),
                finalize(() => this.loading = false)
            );
    }

    private loadTask(): void {
        if (!this.isEdit) {
            this.userManipulationsSource.next();
            this.loading = false;
            return;
        }
        this._battlePassService.getGameTaskById(this.viewModel.id.value)
            .pipe(
                take(1),
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => this.loading = false)
            ).subscribe((result) => {
            this.loadModel(result.result);
        });

    }

    private loadModel(model: WlGameTask): void {
        this.viewModel.id.strictValue = model.id;
        this.viewModel.name.strictValue = model.name;
        this.viewModel.description.strictValue = model.description;
        this.viewModel.deeplink.strictValue = model.deeplink;
        this.viewModel.target.strictValue = model.target;
        this.viewModel.forceComplete.strictValue = model.forceComplete;
        this.viewModel.imageUrl.strictValue = model.imageUrl;
        this.viewModel.imageWebUrl.strictValue = model.imageWebUrl;
        this.viewModel.taskTemplate.strictValue = model.templateTask;
        this.viewModel.humanizedTargetDescription.strictValue = model.humanizedTargetDescription;
        this.viewModel.bullets.strictValue = model.bullets;
        this.viewModel.template.strictValue = model.template;
        this.viewModel.throttling.strictValue = model.throttling;
    }

    private createAddForm(): WlGameTaskCreateForm {
        const form = new WlGameTaskCreateForm();

        form.name = this.viewModel.name.strictValue;
        form.bullets = this.viewModel.bullets.strictValue;
        form.description = this.viewModel.description.strictValue;
        form.deeplink = this.viewModel.deeplink.strictValue;
        form.imageUrl = this.viewModel.imageUrl.strictValue;
        form.imageWebUrl = this.viewModel.imageWebUrl.strictValue;
        form.target = this.viewModel.target.strictValue;
        form.templateTaskId = this.viewModel.taskTemplate.strictValue.id;
        form.forceComplete = this.viewModel.forceComplete.strictValue;
        form.humanizedTargetDescription = this.viewModel.humanizedTargetDescription.strictValue;
        form.templateId = this.viewModel.template.strictValue?.id;
        form.throttlingId = this.viewModel.throttling.strictValue?.id;

        return form;
    }

    private createUpdateForm(): WlGameTaskUpdateForm {
        const form = new WlGameTaskUpdateForm(this.createAddForm());

        form.id = this.viewModel.id.strictValue;

        return form;
    }

    private showListGameTask(): void {
        this._router.navigate(['/menu/battlePass/gameTask']);
    }

    private showGameTaskByNumber(taskId: number): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, ['/menu/battlePass/gameTask/edit', taskId]);
    }

    private load(): void {
        this.loading = true;

        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];

        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.viewModel.taskTemplate.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.loadTask();
        this._titleService.setTitle('Создание / Редактирование задания');
    }

    get purpose(): string {
        if (this.isEdit) {
            return `Редактирование игрового задания для боевого пропуска № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание игрового задания для боевого пропуска';
    }

    get saveButtonText(): string {
        if (this.isEdit) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get isEdit(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get canBeCloned(): boolean {
        return this.isEdit && this.isValid;
    }
}
