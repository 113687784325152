import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    PartnersService,
    TaskTrackerPartnerUpdateForm,
    WlTaskTrackerPartnerListModel,
    WlTaskTrackerPartnerStateTypes,
    WlTaskTrackerPartnerStateTypesExtensions,
} from '../../../autogen/TaskTrackerPartner';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { booleanToString } from '../../../shared/extensions/convert-extensions';
import {
    GaConfirmationService,
    GaMessagingService,
    GaPagingForm, GaSearchManagementStateService,
    GaTableCellTemplate,
    GaTableData, PagingViewModel,
} from '@koddington/ga-common';
import { GaPagedResult } from '@koddington/ga-common/lib/results/results';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';
import { WlBlockUiService } from '../../../shared/services/wl-block-ui-service';

@UntilDestroy()
@Component({
    selector: 'app-partner-registries-list',
    templateUrl: './partner-registries-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class PartnerRegistriesListComponent implements OnInit {
    @ViewChild('reward') reward: TemplateRef<any>;
    @ViewChild('hasActivatePartner') hasActivatePartner: TemplateRef<any>;

    protected partners: GaPagedResult<WlTaskTrackerPartnerListModel>;
    protected tableData: GaTableData<WlTaskTrackerPartnerListModel>;
    private pagingViewModel = new PagingViewModel();

    constructor(private readonly _partnersService: PartnersService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlTaskTrackerPartnerListModel>>,
                private readonly _route: ActivatedRoute,
                private readonly _router: Router,
                private readonly _messaging: GaMessagingService,
                private readonly _confirmation: GaConfirmationService,
                private readonly _blockUi: WlBlockUiService
    ) {
    }

    ngOnInit() {
        this.load();
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchService.nextSearch(page);
    }

    public add(): void {
        this._router.navigate(['menu/dictionary/partner/edit']);
    }

    public activatePartner(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.state = WlTaskTrackerPartnerStateTypes.Active;
        this.update(form);
    }

    public deactivatePartner(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.state = WlTaskTrackerPartnerStateTypes.NotActive;
        this.update(form);
    }

    public acceptReward(partner: WlTaskTrackerPartnerListModel): void {
        if (!partner) {
            throw new Error('partner has empty data');
        }
        const form = this.buildFormForUpdate(partner);
        form.isCanGiveReward = true;
        this.update(form);
    }

    public declineReward(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.isCanGiveReward = false;
        this.update(form);
    }

    public getHasState(elem: WlTaskTrackerPartnerListModel): boolean {
        return elem.state === WlTaskTrackerPartnerStateTypes.Active;
    }

    public getHasReward(elem: WlTaskTrackerPartnerListModel): boolean {
        return elem.isCanGiveReward;
    }

    private load(): void {
        this._searchService.init(u => this._partnersService.list(u as GaPagingForm), this.pagingViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe((res) => {
            this.partners = res;
            this.mapToTable();
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskTrackerPartnerListModel>()
            .addRouterLinkColumn(
                (elem) => elem.id.toString(),
                (elem) => ['edit', elem.id],
                {title: 'Id', widthSize: 100}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 350})
            .addSimpleColumn(
                (elem) => booleanToString(elem.isCanGiveReward),
                {title: 'Может выдать награды', widthSize: 200}
            )
            .addSimpleColumn(
                (elem) => WlTaskTrackerPartnerStateTypesExtensions.format(elem.state),
                {
                    title: 'Статус',
                    widthSize: 100
                },
            )
            .addSimpleColumn((elem) => elem.externalId, {title: 'Внешний Id', widthSize: 200})
            .addSimpleColumn((elem) => elem.virtualHostTitle, {title: 'Имя вирт.хоста', widthSize: 140})
            .addTemplateColumn(
                new GaTableCellTemplate(this.reward, (elem) => {
                    return {
                        partner: elem,
                    };
                }), {
                    title: 'Награды',
                    widthSize: 200
                }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.hasActivatePartner, (elem) => {
                    return {
                        partner: elem,
                    };
                }), {
                    title: 'Активировать статус',
                    widthSize: 300
                }
            );
        data.data = this.partners.results;
        this.tableData = data;
    }

    private update(form: TaskTrackerPartnerUpdateForm): void {
        this._confirmation
            .openDialog(
                'Вы уверены что хотите сохранить изменения состояния',
                'Подтвердить',
                'Отмена'
            )
            .pipe(
                take(1),
                filter((isConfirm) => {
                    if (!isConfirm) {
                        this._messaging.showMessage('Отмена');
                    }

                    return isConfirm as boolean;
                }),
                mergeMap(() => {
                    this._blockUi.start();
                    return this._partnersService.switcherForRewardsAndState(form);
                }),
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => this._blockUi.stop())
            )
            .subscribe(() => {
                this.refresh();
                return;
            });
    }

    private refresh(): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, ['menu/dictionary/partner'], {queryParams: this._route.snapshot.queryParams});
    }

    private buildFormForUpdate(
        partner: WlTaskTrackerPartnerListModel
    ): TaskTrackerPartnerUpdateForm {
        return new TaskTrackerPartnerUpdateForm({
            id: partner.id,
            state: partner.state,
            name: partner.name,
            isCanGiveReward: partner.isCanGiveReward,
            externalId: partner.externalId,
            virtualHostTitle: partner.virtualHostTitle,
        });
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
