<div class="wl-loot-box-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-task-button-footer">
        <button class="wl-button-primary" [disabled]="!isValid"
                (click)="save()">{{ saveButtonText }}
        </button>
    </div>
    <app-wl-strict-input-text class="wl-loot-box-row"
                              title="Название"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.name">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-loot-box-row"
                              title="Описание"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.description">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-loot-box-row"
                              title="Ссылка на изображение"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.imageUrl">
    </app-wl-strict-input-text>
    <app-wl-dropdown class="wl-loot-box-row"
                     [options]="{title: 'Игра', placeholder: 'Выберите тип'}"
                     [strategy]="gameTypesStrategy"
                     (userSelect)="userManipulationsSource.next()"
                     [control]="viewModel.gameType">
    </app-wl-dropdown>
    <app-wl-strict-input-text class="wl-loot-box-row"
                              title="Грейд"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.grade">
    </app-wl-strict-input-text>
    <app-wl-strict-input-number class="wl-loot-box-row"
                                title="Сумма фрибета"
                                placeholder=""
                                min="1"
                                (valueChange)="userManipulationsSource.next()"
                                [control]="viewModel.averageFreebet">
    </app-wl-strict-input-number>
    <button class="wl-btn-form-active" type="button" (click)="addItem()">Добавить награду</button>
    <app-wl-strict-input-number class="wl-loot-box-row"
                                title="Сумма вероятностей"
                                [readonly]="true"
                                (valueChange)="userManipulationsSource.next()"
                                [control]="viewModel.sumProbability">
    </app-wl-strict-input-number>
    <div class="wl-loot-box-row">
        <div class="wl-loot-box-selector" *ngFor="let lootBoxItem of viewModel.rewards.strictValue; let i = index">
            <app-strict-scroll-autocomplete-new class="wl-loot-box-row"
                                                [strategy]="rewardStrategy"
                                                [label]="'Награда'"
                                                [placeholder]="'Выберите награду'"
                                                (userSelect)="userManipulationsSource.next()"
                                                [control]="lootBoxItem.reward"
                                                [instantLoad]="true"
            ></app-strict-scroll-autocomplete-new>
            <app-wl-strict-input-number class="wl-loot-box-row"
                                        title="Вероятность"
                                        placeholder=""
                                        (valueChange)="userManipulationsSource.next()"
                                        [control]="lootBoxItem.probability">
            </app-wl-strict-input-number>
            <button class="wl-season-level-task-button wl-btn-form-inactive" type="button" (click)="deleteItem(i)">Удалить</button>
        </div>
    </div>
</div>
