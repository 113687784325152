import { AbstractModelValidator } from '@koddington/ga-common';
import { WlPartnerRewardsViewModel } from '../../../models/wl-partner-rewards-view-model';

export class PartnerRewardsCrudValidator extends AbstractModelValidator<WlPartnerRewardsViewModel> {
  constructor() {
    super();

    this.ruleForControl((u) => u.name)
      .required('Введите название')
      .minLength(1, 'Название не должено быть пустым');
    this.ruleForControl((u) => u.type).required('Укажите тип награды');
    this.ruleForControl((u) => u.amount)
      .min(0, 'Вознаграждение должно быть больше 0')
      .max(1000000, `Вознаграждение не должно превышать 1000000`)
      .required('Укажите размер вознаграждения');
    this.ruleForControlIf(u => u.freeBetId, v => v.freeBetId.hasStrictValue)
        .isInteger('freeBetId должен быть целым числом');
  }
}
