import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { IPagingViewModel } from '@koddington/ga-common';
import { FilteredOutBetsTargets } from '../../../../../autogen/BattlePass';

export class WlBpFilteredOutBetsPagingViewModel implements IPagingViewModel {
    public offset: StrictFormControl<number> = new StrictFormControl(0);
    public count: StrictFormControl<number> = new StrictFormControl(30);
    public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public filteredOutBetsTargets: StrictFormControl<FilteredOutBetsTargets> = StrictFormControlBuilder.buildNullNumber();
}
