import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IAutocompleteItem } from 'src/app/modules/shared/components/strict-autocomplete/contracts/app-autocomplete-item';
import { IAutocompleteEntityTemplateStrategy } from 'src/app/modules/shared/components/strict-autocomplete/contracts/autocomplete-strategy';
import { StrictScrollAutocompleteViewModel } from 'src/app/modules/shared/components/strict-scroll-autocomplete/models/strict-scroll-autocomplete-view-model';
import { ScrollForm } from './types/scroll-strategy-form';
import { IScrollService } from './types/scroll-service-interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined } from '@koddington/ga-common';

@UntilDestroy()
@Injectable()
export abstract class ScrollStrategy<T, TForm extends ScrollForm> implements IAutocompleteEntityTemplateStrategy<T, StrictScrollAutocompleteViewModel>, OnDestroy {
    protected readonly _entitySource$ = new BehaviorSubject<TForm>(undefined);
    protected readonly _source$ = new BehaviorSubject<Array<T>>([]);
    public _externalModel: StrictScrollAutocompleteViewModel = new StrictScrollAutocompleteViewModel();
    public _sourceClearAutocomplete$: Subject<any>;

    constructor(protected readonly _apiClient: IScrollService<T, TForm>) {
        this.subscriptionSearch();
    }

    ngOnDestroy(): void {
        this._source$.unsubscribe();
        this._entitySource$.unsubscribe();
    }

    protected subscriptionSearch(): void {
        this._entitySource$
            .pipe(
                filter((value) => !isNullOrUndefined(value)),
                switchMap((u) => this.load(u)),
                untilDestroyed(this)
            )
            .subscribe((u) => this._source$.next(u));
    }

    protected load(item: TForm, fnRules: (form: TForm) => boolean = null): Observable<any> {
        item.isScrollStrategy = true;

        if (isNullOrUndefined(item) || isNullOrUndefined(item.term) || item.term.length === 0) {
            return of([]);
        }

        if (!isNullOrUndefined(fnRules)) {
            if (fnRules(item)) return of([]);
        }

        return this._apiClient.search(item).pipe(
            filter((v) => v.isCorrect),
            map((result) => result.result),
            untilDestroyed(this)
        );
    }

    public setCallback(clearSourse$: Subject<any>): void {
        this._sourceClearAutocomplete$ = clearSourse$;
    }

    public emitUpdateEvent(): void {
        if (isNullOrUndefined(this._externalModel)) {
            throw new Error('External model for scroll autocomplete not bounded');
        }
        let form: TForm = {
            term: '',
            count: 0,
            offset: 0,
            isScrollStrategy: true,
        } as TForm;

        form.term = this._externalModel.term.strictValue;
        form.count = this._externalModel.count.strictValue;
        form.offset = this._externalModel.offset.strictValue;
        this._entitySource$.next(form);
    }
    public bindControlModel(model: StrictScrollAutocompleteViewModel): void {
        this._externalModel = model;
    }

    abstract convert(model: T): IAutocompleteItem<T>;

    public convert_(func: () => IAutocompleteItem<T>): IAutocompleteItem<T> {
        return func();
    }

    public updateSource(term: string): void {
        let form: TForm = {
            term: '',
            count: 0,
            offset: 0,
            isScrollStrategy: true,
        } as TForm;

        form.term = term;
        form.count = 20;
        form.offset = 0;
        this._entitySource$.next(form);
    }

    public getSource(): Observable<T[]> {
        return this._source$.asObservable().pipe(untilDestroyed(this));
    }
}
