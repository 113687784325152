import { Component, OnInit } from '@angular/core';
import { formatDateTime, GaMessagingService, GaStrictModelFactory, GaTableData, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GamblerBindingSearchModel } from './models/gambler-binding-search-model';
import {
    GamblerBindingSearchForm,
    PartnersService, WlCrmTaskGroupSubscriptionFrontModel,
    WlTaskTrackerGamblerBindingProfileModel,
    WlTaskTrackerPartnerGamblerBinding
} from '../../../autogen/TaskTrackerPartner';
import { nullableBooleanToString } from '../../../shared/extensions/convert-extensions';
import { Subject } from 'rxjs';
import { TrackerGamblerBindingProfileValidator } from './validators/tracker-gambler-binding-profile-validator';

@UntilDestroy()
@Component({
    selector: 'tracker-gambler-profile',
    templateUrl: './tracker-gambler-binding-profile.component.html'
})
export class TrackerGamblerBindingProfileComponent implements OnInit {

    protected searchModel: GamblerBindingSearchModel = new GamblerBindingSearchModel();
    protected loading = false;
    public tableData: GaTableData<WlTaskTrackerGamblerBindingProfileModel>;
    public taskInfoTableData: GaTableData<WlCrmTaskGroupSubscriptionFrontModel>;
    public result: WlTaskTrackerGamblerBindingProfileModel;
    public userManipulationsSource = new Subject<void>();
    private readonly validator = new TrackerGamblerBindingProfileValidator();
    public errors: StrictError[] = [];

    constructor(private readonly messaging: GaMessagingService,
                private readonly service: PartnersService,
                private readonly navigation: SearchNavigationService,
                private readonly activeRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.searchModel);
        });
        this.userManipulationsSource.next();

        this.activeRoute.queryParams
            .pipe(
                map((value) => this.initModel(value)),
                filter(() => !this.isSearchForbidden),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    protected search(): void {
        if (this.errors.length > 0) {
            return;
        }

        const params: Params = {
            userId: this.searchModel.userId.strictValue,
            partnerId: this.searchModel.partnerId.strictValue,
            userHash: this.searchModel.userHash.strictValue
        };
        this.navigation.search(this.activeRoute, params);
    }

    private load(): void {
        if (this.errors.length > 0) {
            return;
        }

        this.service
            .getGamblerInfo(this.getForm())
            .pipe(take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe((res) => {
                this.result = res?.result;
                GaStrictModelFactory.fromModelToStrict(this.searchModel, res?.result);
                this.mapToTable();
                this.mapTaskInfoToTable();
            });
    }

    private initModel(params: Params): void {
        this.loading = true;
        this.searchModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.searchModel.partnerId.strictValue = !isNullOrUndefined(params['partnerId']) ? Number(params['partnerId']) : null;
        this.searchModel.userHash.strictValue = !isNullOrUndefined(params['userHash']) ? String(params['userHash']) : null;
    }

    private getForm(): GamblerBindingSearchForm {
        return GaStrictModelFactory.fromStrictToModel(GamblerBindingSearchForm, this.searchModel);
    }

    private mapToTable(): void {
        this.tableData = new GaTableData<WlTaskTrackerPartnerGamblerBinding>()
            .addSimpleColumn((elem) => elem?.partnerId, {title: 'Id партнёра:', widthSize: 100})
            .addSimpleColumn((elem) => elem?.code, {title: 'Хэш:', widthSize: 320})
            .addSimpleColumn((elem) => formatDateTime(elem?.createdAt),
                {title: 'Дата привязки', widthSize: 150})
            .setData(this.result.userPartners);

    }

    private getAllRecurrentDates(elem: WlCrmTaskGroupSubscriptionFrontModel): string {
        if (elem?.recurrentTaskCompletionDates?.length > 0) {
            return elem.recurrentTaskCompletionDates.map((dayjsObject) => formatDateTime(dayjsObject)).join('\n');
        }
        return '';
    }

    private mapTaskInfoToTable(): void {
        this.taskInfoTableData = new GaTableData<WlCrmTaskGroupSubscriptionFrontModel>()
            .addSimpleColumn((elem) => formatDateTime(elem?.createdAt),
                {title: 'Дата подписки:', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.closedAt),
                {title: 'Дата выполнения / завершения:', widthSize: 150})
            .addSimpleColumn((elem) => elem.currentGroupProgress + '/' + elem.groupTarget,
                {title: 'Прогресс: ', widthSize: 150})
            .addSimpleColumn((elem) => nullableBooleanToString(elem.currentGroupProgress === elem.groupTarget),
                {title: 'Выполнено?:', widthSize: 150})
            .addRouterLinkColumn(
                (elem) => elem.taskGroupId,
                (elem) => ['/menu/battlePass/task/edit', elem.taskGroupId],
                {title: 'Ссылка на бук. задание:', widthSize: 150})
            .addSimpleColumn((elem) => elem?.externalSubscriptionId,
                {title: 'Id подписки партнёра:', widthSize: 150})
            .addSimpleColumn((elem) => nullableBooleanToString(elem?.isRecurrent),
                {title: 'Рекуррентное задание?', widthSize: 150})
            .addSimpleColumn((elem) => this.getAllRecurrentDates(elem),
                {title: 'Даты выполнения рекуррентного задания:', widthSize: 300})
            .setData(this.result.tasksInfo);
    }

    get isSearchForbidden(): boolean {
        return isNullOrUndefined(this.searchModel.userId.strictValue) && isNullOrUndefined(this.searchModel.userHash.strictValue);
    }

    get isShowPrompt(): boolean {
        return !this.searchModel.userId.strictValue
            && !this.searchModel.partnerId.strictValue
            && !this.searchModel.userHash.strictValue;
    }

    get isProfileByIdOnly(): boolean {
        return this.result.partnerUserProfileInfo.profileByGamblerIdOnly;
    }
}
