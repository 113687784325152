import { Component, OnInit } from '@angular/core';
import { GaPagedResult, GaPagingForm as PagingForm } from '@koddington/ga-common/lib/results/results';
import {
    GaPagingForm, GaSearchManagementStateService, PagingViewModel,
} from '@koddington/ga-common';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    RewardsService,
    WlTaskTrackerRewardListModel,
    WlTaskTrackerRewardTypesExtensions,
} from '../../../autogen/TaskTrackerRewards';
import { GaTableData } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-partner-rewards-list',
    templateUrl: './partner-rewards-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class PartnerRewardsListComponent implements OnInit {
    protected rewards: GaPagedResult<WlTaskTrackerRewardListModel>;
    protected tableData: GaTableData<WlTaskTrackerRewardListModel>;

    private searchViewModel = new PagingViewModel();

    constructor(private readonly _partners: RewardsService,
                private readonly _router: Router,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlTaskTrackerRewardListModel>>
    ) {
    }

    ngOnInit() {
        this.init();
    }

    protected pageChanged(page: GaPagingForm): void {
        this._searchService.nextSearch(page);
    }

    protected add(): void {
        this._router.navigate(['menu/dictionary/reward/edit']);
    }

    private init(): void {
        this._searchService.init(u => this._partners.list(u as PagingForm), this.searchViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(result => {
            this.rewards = result;
            this.mapToTable();
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskTrackerRewardListModel>()
            .addRouterLinkColumn(
                elem => elem.id.toString(),
                elem => ['edit', elem.id],
                {title: 'Id', widthSize: 100}
            )
            .addSimpleColumn(elem => elem.name, {title: 'Название', widthSize: 500})
            .addSimpleColumn(elem => WlTaskTrackerRewardTypesExtensions.format(elem.type), {title: 'Тип', widthSize: 300})
            .addSimpleColumn(elem => elem.amount.toString(), {title: 'Вознаграждение', widthSize: 300})
            .addSimpleColumn(elem => elem.freeBetId, { title: 'FreeBet Id', widthSize: 300 });

        data.data = this.rewards.results;
        this.tableData = data;
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
