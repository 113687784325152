import { WlCrmTaskViewModel } from '../../models/wl-crm-task-view-model';
import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { WlBattlePassTaskTypes, WlBetTypes, WlCrmTaskTargetTypes, WlFiltersTypes, WlTaskComparisonConditionsTypes } from 'src/app/modules/autogen/BattlePass';
import { StrictValidationRules } from '../../../shared/validation-rules/strict-validation-rules';
import { isMoreThanValue } from 'src/app/modules/shared/extensions/range-comparison-utils';
import controlIsCorrectAndEqual = StrictValidationRules.controlIsCorrectAndEqual;


export class BattlePassTaskItemValidator extends AbstractModelValidator<WlCrmTaskViewModel> {
    constructor(taskType: WlBattlePassTaskTypes, targetType: WlCrmTaskTargetTypes, isCollectInExpress: boolean, isGrouped: boolean) {
        super();

        this.ruleForControlIf(u => u.betCount,
            () => ((targetType === WlCrmTaskTargetTypes.ByCount && taskType !== WlBattlePassTaskTypes.Deposits)
                || (targetType === WlCrmTaskTargetTypes.ByCount && taskType === WlBattlePassTaskTypes.Redeem))
                && !isCollectInExpress)
            .min(1)
            .required('Укажите число ставок')
            .isInteger();

        this.ruleForControlIf(u => u.eventCountExpressRange.strictValue.value, v =>
            controlIsCorrectAndEqual(v.betType, WlBetTypes.Express) && !isCollectInExpress)
            .required('Укажите диапазон экспресс ставок')
            .isInteger('Диапазон экспресс ставок должен быть целым числом')
            .customRule((v, model) => {
                if (!isMoreThanValue(model.eventCountExpressRange.strictValue, 1)) {
                    return new StrictError('betSumRangeError', 'Событий в экспрессе не может быть меньше или равно 1');
                }

                return null;
            })
            .max(20, 'Событий в экспрессе не может быть больше 20')
            .isInteger();

        this.ruleForControlIf(u => u.championshipId, v => v.championshipId.hasStrictValue)
            .isInteger();

        this.ruleForControlIf(u => u.eventCountExpressRange.strictValue.condition, v =>
            controlIsCorrectAndEqual(v.betType, WlBetTypes.Express) && !isCollectInExpress)
            .required('Укажите тип сравнения диапазона экспресс ставок');

        const needToValidateSeveralBetsSumRange = () =>
            (isGrouped !== true || targetType !== WlCrmTaskTargetTypes.BySum) &&
            ((taskType === WlBattlePassTaskTypes.Bets || taskType === WlBattlePassTaskTypes.Redeem) && targetType === WlCrmTaskTargetTypes.BySum);

        this.ruleForControlIf(u => u.severalBetsSumRange.strictValue.value, needToValidateSeveralBetsSumRange)
            .min(1)
            .required('Укажите сумму ставок')
            .isInteger('Сумма ставок должна быть целым числом');

        this.ruleForControlIf(u => u.severalBetsSumRange.strictValue.condition, needToValidateSeveralBetsSumRange)
            .required('Укажите тип сравнения суммы ставок');

        this.ruleForControlIf(u => u.betType, _ => this.requiredToShowBetsInfo(taskType))
              .required('Укажите тип ставки');

        this.ruleForControlIf(u => u.totalWinAmountRange.strictValue.condition,
            _ => taskType === WlBattlePassTaskTypes.Win && targetType === WlCrmTaskTargetTypes.BySum && !isCollectInExpress
        )
            .required('Укажите тип сравнения для выигрышей');


        this.ruleForControlIf(u => u.betRateRange.strictValue.value,
            _ => this.requiredToShowBetsInfo(taskType)  && !isCollectInExpress)
            .required('Укажите коэффициент ставки')
            .min(1.01, 'Коэффициент ставки должен быть больше или равен 1.01');

        this.ruleForControlIf(u => u.betRateRange.strictValue.condition,
            _ => this.requiredToShowBetsInfo(taskType) && !isCollectInExpress)
            .required('Укажите тип сравнения коэффициента ставки');

        this.ruleForControlIf(u => u.lineRateRange.strictValue.value,
            v => controlIsCorrectAndEqual(v.betType, WlBetTypes.Express) ||
                controlIsCorrectAndEqual(v.betType, WlBetTypes.Any))
            .required('Укажите коэффициент линии')
            .min(1.01, 'Коэффициент линии должен быть больше или равен 1.01');

        this.ruleForControlIf(u => u.lineRateRange.strictValue.condition,
            v => controlIsCorrectAndEqual(v.betType, WlBetTypes.Express) ||
                controlIsCorrectAndEqual(v.betType, WlBetTypes.Any))
            .required('Укажите тип сравнения коэффициента линии');

        this.ruleForControlIf(u => u.depositsSumRange.strictValue.value,
            v => taskType === WlBattlePassTaskTypes.Deposits && targetType === WlCrmTaskTargetTypes.ByCount)
            .required('Укажите сумму депозита')
            .min(1, 'Сумма депозита должна быть больше 0')
            .isInteger('Сумма депозита должна быть целым числом');

        this.ruleForControlIf(u => u.depositsSumRange.strictValue.condition,
            v => taskType === WlBattlePassTaskTypes.Deposits && targetType === WlCrmTaskTargetTypes.ByCount)
            .required('Укажите тип сравнения депозита');

        this.ruleForControlIf(u => u.depositsCount,
            v => taskType === WlBattlePassTaskTypes.Deposits && targetType === WlCrmTaskTargetTypes.ByCount)
            .min(1)
            .required('Укажите количество депозитов')
            .isInteger();

        this.ruleForControlIf(u => u.totalDepositsAmountRange.strictValue.value,
            v => taskType === WlBattlePassTaskTypes.Deposits && targetType === WlCrmTaskTargetTypes.BySum)
            .required('Укажите общую сумму депозита')
            .min(1, 'Общая сумма депозитов должна быть больше 0')
            .isInteger('Общая сумма депозитов должна быть целым числом');

        this.ruleForControlIf(u => u.totalDepositsAmountRange.strictValue.condition,
            v => taskType === WlBattlePassTaskTypes.Deposits && targetType === WlCrmTaskTargetTypes.BySum)
            .required('Укажите тип сравнения депозита');

        this.ruleForControlIf(u => u.totalWinAmountRange.strictValue.value,
            v => taskType === WlBattlePassTaskTypes.Win && targetType === WlCrmTaskTargetTypes.BySum && !isCollectInExpress)
            .min(1)
            .required('Укажите сумму выигрышей')
            .isInteger('Сумма выигрышей должна быть целым числом');

        const needToValidateForBetSumRange = (v: WlCrmTaskViewModel) =>
            targetType === WlCrmTaskTargetTypes.ByCount && !isCollectInExpress;

        this.ruleForControlIf(u => u.betSumRange, needToValidateForBetSumRange )
            .customRule((_, model) => {
                const field = model.betSumRange.strictValue;
                if (field.condition.strictValue === WlTaskComparisonConditionsTypes.Range) {
                    if (!field.minValue.hasStrictValue || field.minValue.strictValue < 0) {
                        return new StrictError('betSumRangeError', 'Минимальная граница суммы ставки не может быть меньше 0');
                    }

                    if (!field.maxValue.hasStrictValue || field.maxValue.strictValue < field.minValue.strictValue) {
                        return new StrictError('betSumRangeError', 'Максимальная граница суммы ставки не может быть меньше минимальной');
                    }

                    return null;
                }

                if (field.value.strictValue < 1) {
                    return new StrictError('betSumRangeError', 'Сумма ставки должна быть больше 0');
                }

                return null;
            });

        this.ruleForControlIf(u => u.betSumRange.strictValue.condition, needToValidateForBetSumRange)
            .customRule((_, model) => {
                if (model.betSumRange?.strictValue.value.hasStrictValue && !model.betSumRange?.strictValue.condition.hasStrictValue) {
                    return new StrictError('betSumRangeError', 'Укажите тип сравнения суммы ставок');
                }

                return null;
            });

        this.ruleForControlIf(
            u => u.betWinAmountRange.strictValue.value,
            _ => targetType === WlCrmTaskTargetTypes.ByCount && taskType === WlBattlePassTaskTypes.Win && !isCollectInExpress)
            .min(1, 'Сумма выигрыша ставки не может быть отрицательной или равной нулю')
            .isInteger('Сумма выигрыша ставки должна быть целым числом');

        this.ruleForControlIf(
            u => u.betWinAmountRange.strictValue.condition,
            _ => targetType === WlCrmTaskTargetTypes.ByCount && taskType === WlBattlePassTaskTypes.Win  && !isCollectInExpress)
            .customRule((_, model) => {
                if (model.betWinAmountRange?.strictValue.value.hasStrictValue && !model.betWinAmountRange?.strictValue.condition.hasStrictValue) {
                    return new StrictError('betSumRangeError', 'Укажите тип сравнения суммы выигрыша ставки');
                }

                return null;
            });

        this.ruleForControlIf(u => u.isExcludedGames,
            v => this.requiredToShowBetsInfo(taskType) && v.isExcludedGames.strictValue === WlFiltersTypes.Including
        )
            .customRule((fieldValue, model) => {
                if (model.games.strictValue?.length !== 1) {
                    return new StrictError('gameCount', 'Дисциплин не может быть больше 1');
                }
                return null;
            });

        this.ruleForControlIf(
            u => u.isExcludedGames,
            v => this.requiredToShowBetsInfo(taskType) && v.isExcludedGames.hasStrictValue
        )
            .customRule((fieldValue, model) => {
            if (model.games.strictValue?.length === 0) {
                return new StrictError('gameCount', 'Дисциплины должны быть заполнены');
            }

            return null;
        });

        this.ruleForControlIf(u => u.isExcludedGames,
            v => this.requiredToShowBetsInfo(taskType)  && v.games.strictValue?.length > 0)
            .required('Выберите тип вклчючения');

        this.ruleForControlIf(u => u.isExcludedSportType,
            v => this.requiredToShowBetsInfo(taskType)  && v.sportTypes.strictValue?.length > 0)
            .required('Выберите тип включения');

        this.ruleForControlIf(u => u.isExcludedSportType,
            v => this.requiredToShowBetsInfo(taskType) && v.isExcludedSportType.hasStrictValue)
            .customRule((fieldValue, model) => {
                if (model.sportTypes.strictValue?.length === 0) {
                    return new StrictError('sportTypesCount', 'Виды спорта должны быть заполнены');
                }

                return null;
            });
    }

    private requiredToShowBetsInfo(taskType: WlBattlePassTaskTypes) {
        return taskType === WlBattlePassTaskTypes.Bets || taskType === WlBattlePassTaskTypes.Win || taskType === WlBattlePassTaskTypes.Redeem;
    }
}
