import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { formatDateTime, GaPagedResult, GaPagingForm, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { BattlePassStatisticsService, DepositsForTasksSearchForm, WlDepositsForTasksModel } from '../../../../autogen/BattlePass';
import { SearchNavigationService } from '../../../../shared/services/search-navigation.service';
import { GaTableData } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-deposits-tasks-statistics',
    templateUrl: './wl-battle-pass-deposits-tasks-statistics.component.html',
    styleUrls: ['./wl-battle-pass-deposits-tasks-statistics.component.scss'],
})
export class WlBattlePassDepositsTasksStatisticsComponent implements OnInit {
    public depositsForTasks: GaPagedResult<WlDepositsForTasksModel>;
    public loading: boolean;
    public searchUserId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public searchUserTaskId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public searchUserSubscriptionId: StrictFormControl<number> = new StrictFormControl<number>(null);
    private count: StrictFormControl<number> = new StrictFormControl<number>(null);
    private offset: StrictFormControl<number> = new StrictFormControl<number>(null);

    tableData: GaTableData<WlDepositsForTasksModel>;
    constructor(
        private readonly _battlePassStatisticsService: BattlePassStatisticsService,
        private readonly _activeRoute: ActivatedRoute,
        private readonly _navigation: SearchNavigationService
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public init(): void {
        this._activeRoute.queryParams
            .pipe(
                map((param) => this.initModel(param)),
                filter(() => this.searchUserId.hasStrictValue || this.searchUserTaskId.hasStrictValue),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    public search(): void {
        this.navigateWithParameters();
    }

    public pageChanged(page: GaPagingForm): void {
        this.offset.strictValue = page.offset;
        this.navigateWithParameters();
    }

    private load(): void {
        this.loading = true;

        const form = this.createForm();

        this._battlePassStatisticsService
            .getDepositsForTasksStatistics(form)
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.depositsForTasks = value;
                this.mapToTable();
                this.loading = false;
            });
    }

    private initModel(params: Params): void {
        this.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.searchUserId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.searchUserTaskId.strictValue = !isNullOrUndefined(params['userTaskId']) ? Number(params['userTaskId']) : null;
        this.searchUserSubscriptionId.strictValue = !isNullOrUndefined(params['userSubscriptionId']) ? Number(params['userSubscriptionId']) : null;
    }

    private createForm(): DepositsForTasksSearchForm {
        const form = new DepositsForTasksSearchForm();
        form.offset = this.offset.getStrictValueOrDefault(0);
        form.count = this.count.getStrictValueOrDefault(30);
        form.userId = this.searchUserId.strictValue;
        form.userTaskId = this.searchUserTaskId.strictValue;
        form.userSubscriptionId = this.searchUserSubscriptionId.strictValue;

        return form;
    }

    private navigateWithParameters(): void {
        const params: Params = {
            offset: this.offset.strictValue,
            count: this.count.strictValue,
            userId: this.searchUserId.strictValue,
            userTaskId: this.searchUserTaskId.strictValue,
            userSubscriptionId: this.searchUserSubscriptionId.strictValue,
        };

        this._navigation.search(this._activeRoute, params);
    }

    private mapToTable(): void {
        const data = new GaTableData<WlDepositsForTasksModel>();

        this.tableData = data
            .addSimpleColumn((elem) => elem.id, { title: 'Id', widthSize: 100 })
            .addSimpleColumn((elem) => elem.userId, { title: 'Пользователь', widthSize: 150 })
            .addSimpleColumn((elem) => elem.userTaskGroupSubscriptionId, { title: 'Задание пользователя', widthSize: 170 })
            .addSimpleColumn((elem) => elem.userTaskSubscriptionId, { title: 'Подписка', widthSize: 100 })
            .addSimpleColumn((elem) => elem.amount, { title: 'Сумма депозита', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.operationDate), { title: 'Дата депозита', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.savedDate), { title: 'Дата сохранения', widthSize: 100 })
            .setData(this.depositsForTasks.results);
    }
}
