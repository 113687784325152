<div class="wl-container">
    <app-wl-page-header [header]="header()"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>

    <div class="wl-game-task-row">
        <button class="wl-button-primary wl-btn-push-save-wrapper" [disabled]="!isValid" (click)="onSave()">
            {{ saveButtonText }}
        </button>
    </div>

    <div class="wl-game-task-row">
        <app-wl-strict-input-text
            class="wl-push-template-add-input"
            title="Название *"
            [control]="viewModel.name"
            (valueChange)="userManipulationsSource.next()"
            placeholder="Введите название"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-game-task-row">
        <app-wl-strict-input-text
            class="wl-push-template-add-input"
            title="Партнёрский ID задания *"
            [control]="viewModel.externalPartnerId"
            (valueChange)="userManipulationsSource.next()"
            placeholder="Введите партнёрский ID задания"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-game-task-row">
        <app-wl-strict-input-text
            class="wl-push-template-add-input"
            title="Описание задания *"
            [control]="viewModel.description"
            (valueChange)="userManipulationsSource.next()"
            placeholder="Введите описание задания"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-game-task-row">
        <app-wl-dropdown [control]="viewModel.icon"
                         [strategy]="gameIconsStrategy"
                         [options]="{ placeholder: 'Выберите иконку', title: 'Иконка для МП' }"
                         (userSelect)="userManipulationsSource.next()">
        </app-wl-dropdown>
    </div>
</div>
