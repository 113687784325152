import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';
import { WlDtPromotionDayListModel, WlPromotionsDaysSearchForm, WlPromoTypes } from '../../autogen/DailyTasks';
import { DailyTaskDaysScrollServiceAdapter } from '../services/wrapper-services/daily-task-days.scroll-service-adapter';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class DailyTaskDaysAutocompleteStrategy extends ScrollStrategy<WlDtPromotionDayListModel, WlPromotionsDaysSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: DailyTaskDaysScrollServiceAdapter,
                protected readonly _promoTypeRule: () => WlPromoTypes = () => null) {
        super(_apiClient);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlDtPromotionDayListModel): IAutocompleteItem<WlDtPromotionDayListModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: `[${model.id.toString()}] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }

    protected override load(item: WlPromotionsDaysSearchForm, fnRules: (form: WlPromotionsDaysSearchForm) => boolean = null): Observable<any> {
        item.dayType = this._promoTypeRule();

        return this._apiClient.search(item).pipe(
            filter((v) => v.isCorrect),
            map((result) => result.result),
            untilDestroyed(this)
        );
    }
}
