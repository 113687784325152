import {WlFreeBetLootBoxContentForm, WlFreeBetLootBoxCreateForm} from '../../../../autogen/Shared';
import {WlBattlePassFreeBetLootBoxContentsViewModel} from '../view-models/wl-battle-pass-free-bet-loot-box-contents-view-model';
import {WlBattlePassFreeBetLootBoxCrudViewModel} from '../view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';
import { GaStrictModelFactory } from '@koddington/ga-common';
import MapExpression = GaStrictModelFactory.MapExpression;
import gaMap = GaStrictModelFactory.gaMap;

export class WlBattlePassFreeBetLootBoxMappers {
    public static mapLootBoxFormToStrict(viewModel: WlBattlePassFreeBetLootBoxCrudViewModel, form: WlFreeBetLootBoxCreateForm) {
        GaStrictModelFactory.fromModelToStrict(viewModel, form, [
            {
                sourceMapProperty: 'lootBoxContents',
                targetMapProperty: 'lootBoxContents',
                mapObject: gaMap(form.lootBoxContents, (value) => {
                    const mapped = new WlBattlePassFreeBetLootBoxContentsViewModel();
                    GaStrictModelFactory.fromModelToStrict(mapped, value);
                    return mapped;
                }),
            },
        ]);
    }

    public static mapLootBoxStrictToForm<T extends WlFreeBetLootBoxCreateForm>( type: new (...args: any[]) => T , viewModel: WlBattlePassFreeBetLootBoxCrudViewModel): T {
        return GaStrictModelFactory.fromStrictToModel(type, viewModel, this.strictToModelMappers(viewModel));
    }

    private static strictToModelMappers(viewModel: WlBattlePassFreeBetLootBoxCrudViewModel): MapExpression[] {
        return [
            {
                sourceMapProperty: 'lootBoxContents',
                targetMapProperty: 'lootBoxContents',
                mapObject: gaMap(viewModel?.lootBoxContents.strictValue, (value) => {
                    return GaStrictModelFactory.fromStrictToModel(WlFreeBetLootBoxContentForm, value);
                }),
            },
        ];
    }
}
