import { Component, OnInit } from '@angular/core';
import {
    BattlePassStatisticsService,
    WlBattlePassFilteredOutsBetsModel,
    BattlePassFilteredOutBetsFilterForm,
} from 'src/app/modules/autogen/BattlePass';
import { formatDateTime, GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaTableData } from '@koddington/ga-common';
import { FilteredOutBetsTargetsStrategy } from './strategy/filtered-out-bets-targets.strategy';
import { WlBpFilteredOutBetsPagingViewModel } from './view-models/wl-bp-filtered-out-bets.paging-view-model';
import { GaSearchManagementStateService } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-filtered-out-bets-statistics',
    templateUrl: './wl-battle-pass-filtered-out-bets-statistics.component.html',
    styleUrls: ['./wl-battle-pass-filtered-out-bets-statistics.component.scss'],
    providers: [GaSearchManagementStateService]
})
export class WlBattlePassFilteredOutBetsStatisticsComponent implements OnInit {
    protected logs: GaPagedResult<WlBattlePassFilteredOutsBetsModel>;
    protected pagingViewModel = new WlBpFilteredOutBetsPagingViewModel();

    tableData: GaTableData<WlBattlePassFilteredOutsBetsModel>;

    constructor(protected readonly _filteredOutBetsTargetsStrategy: FilteredOutBetsTargetsStrategy,
                private readonly _battlePassStatisticsService: BattlePassStatisticsService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlBattlePassFilteredOutsBetsModel>>
    ) {
    }

    public ngOnInit(): void {
        this.init();
    }


    public pageFiltered(page: GaPagingForm = null): void {
        this._searchService.nextSearch(page, true);
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchService.nextSearch(page);
    }

    private init(): void {
        this._searchService.init(u => this._battlePassStatisticsService.getFilteredOutBetsList(u as BattlePassFilteredOutBetsFilterForm), this.pagingViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.logs = res;
            this.mapToTable(res.results);
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(logs: WlBattlePassFilteredOutsBetsModel[]): void {
        const data = new GaTableData<WlBattlePassFilteredOutsBetsModel>();

        this.tableData = data
            .addSimpleColumn((elem) => elem.userId, {title: 'Пользователь', widthSize: 150})
            .addSimpleColumn((elem) => elem.taskId, {title: 'Id задания', widthSize: 170})
            .addSimpleColumn((elem) => elem.betId, {title: 'Id ставки', widthSize: 150})
            .addSimpleColumn((elem) => elem.filterName, {title: 'Имя фильтра', widthSize: 350})
            .addSimpleColumn((elem) => formatDateTime(elem.logged), {title: 'Дата сохранения', widthSize: 350})
            .setData(logs);
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
