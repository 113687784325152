<app-wl-page-header header="Игровые задания для боевого пропуска"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить задание</button>
</div>
<div class="wl-search-row">
    <app-wl-strict-input-text
            class="wl-search-input-wrapper"
            placeholder="Поиск по названию"
            [isSearch]="true"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
            [control]="viewSearchModel.term"
    ></app-wl-strict-input-text>
</div>

<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="result" [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
