import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm, PagingViewModel } from '@koddington/ga-common';
import { BattlePassService, WlGameTaskTemplate } from 'src/app/modules/autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaSearchManagementStateService } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-wl-battle-pass-game-task-templates',
    templateUrl: './wl-battle-pass-game-task-templates.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassGameTaskTemplatesComponent implements OnInit {
    public result: GaPagedResult<WlGameTaskTemplate>;
    public tableData: GaTableData<WlGameTaskTemplate>;
    public viewSearchModel: PagingViewModel = new PagingViewModel();

    constructor(private readonly _battlePassService: BattlePassService,
                private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlGameTaskTemplate>>,
                private readonly _router: Router) {
    }

    ngOnInit(): void {
        this.init();
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/gameTaskTemplates/edit']);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._battlePassService.listGameTaskTemplates(form as GaPagingForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    private mapToTable() {
        const data = new GaTableData<WlGameTaskTemplate>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.externalPartnerId,
                (elem) => ['edit', elem.id],
                {title: 'Партнёрский Id', widthSize: 400}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 350})
            .addSimpleColumn((elem) => elem.description, {title: 'Описание задания', widthSize: 390})
            .addSimpleColumn((elem) => elem.icon.code, {title: 'Иконка', widthSize: 250})
            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
