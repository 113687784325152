<div class="wl-range-condition-body-block">
        <app-wl-dropdown class="wl-range-condition-margin"
                         [strategy]="conditionsTypeStrategy"
                         [options]="{title: title, placeholder: 'Выберите сравнение'}"
                         (userSelect)="change.next()"
                         [control]="viewModel.strictValue.condition">
        </app-wl-dropdown>
    <div class="wl-app-strict-condition-wrapper">
        <app-wl-strict-input-number *ngIf="!showRange"
                                    class="wl-range-condition-item"
                                   [control]="viewModel.strictValue.value"
                                   [min]="minValue"
                                   [step]="step"
                                   (valueChange)="change.emit()">
        </app-wl-strict-input-number>
        <ng-container *ngIf="showRange">
            <app-wl-strict-input-number class="wl-values-range-item"
                                        [control]="viewModel.strictValue.minValue"
                                        [min]="minValue"
                                        [step]="step"
                                        (valueChange)="change.emit()">
            </app-wl-strict-input-number>
            <app-wl-strict-input-number class="wl-values-range-item"
                                        [control]="viewModel.strictValue.maxValue"
                                        [min]="minValue"
                                        [step]="step"
                                        (valueChange)="change.emit()">
            </app-wl-strict-input-number>
        </ng-container>
    </div>

</div>
