import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlRateRaceStatusModeration } from '../../autogen/BattlePass';
import { IPagingViewModel } from '@koddington/ga-common';

export class RateRaceNicknameSearchViewModel implements IPagingViewModel {
    public seasonId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public fromLevelOrder: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public toLevelOrder: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public statusModeration?: StrictFormControl<WlRateRaceStatusModeration> = StrictFormControlBuilder.buildNullNumber();
    public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public nickname: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    public count: StrictFormControl<number> = new StrictFormControl<number>(30);
}
