import { Component, Inject } from '@angular/core';
import { GaConfig } from '@koddington/ga-common';
import { environment } from 'src/environments/environment';
import { APP_BASE_HREF } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(@Inject(APP_BASE_HREF) protected _baseHref: string) {
        this._baseHref ??= '';
        const locationPathParts = window.location.pathname.split('/').filter(u => u !== '');
        const baseHrefPathParts = _baseHref.split('/').filter(u => u !== '');

        const isEmptyPath = locationPathParts.length === 0;
        const isBaseHrefPath = locationPathParts.length === baseHrefPathParts.length && locationPathParts.every((u, i) => baseHrefPathParts[i] === u);

        if (isEmptyPath || isBaseHrefPath) {
            // see https://github.com/angular/angular/issues/10726
            window.location.replace(baseHrefPathParts.map(u => `/${u}`).join('') + '/default-page');
        }

        GaConfig.host = environment.host;
        GaConfig.production = environment.production;
        GaConfig.protocol = window.location.protocol;
        GaConfig.headers = {WlApp: 'angular'};
    }

    title = 'battle-pass-admin';
}
