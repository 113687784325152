<div class="wl-game-task-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <app-wl-strict-input-text class="wl-game-task-row"
                              [control]="viewModel.name"
                              title="Название"
                              placeholder="Введите название задания"
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-game-task-row"
                              [control]="viewModel.description"
                              title="Описание"
                              placeholder="Введите описание задания"
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-game-task-row"
                              [control]="viewModel.bullets"
                              title="Буллиты"
                              placeholder="Дополнительные условия выполнения задания"
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-game-task-row"
                              [control]="viewModel.humanizedTargetDescription"
                              title="Название целевого параметра"
                              placeholder="Введите название целевого параметра для отображения пользователям"
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-strict-scroll-autocomplete-new class="wl-game-task-row"
                                        placeholder="Выберите шаблон задания"
                                        [label]="'Шаблон'"
                                        [isSearch]="false"
                                        [control]="viewModel.taskTemplate"
                                        (valueChange)="userManipulationsSource.next()"
                                        [strategy]="templateStrategy">
    </app-strict-scroll-autocomplete-new>
    <app-wl-strict-input-number class="wl-game-task-row"
                                title="Целевой показатель"
                                (valueChange)="userManipulationsSource.next()"
                                [control]="viewModel.target">
    </app-wl-strict-input-number>
    <app-wl-strict-input-text class="wl-game-task-row"
                              title="Deeplink"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.deeplink">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-game-task-row"
                              title="ImageUrl"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.imageUrl">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-game-task-row"
                              title="ImageUrl Web"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.imageWebUrl">
    </app-wl-strict-input-text>
    <app-wl-strict-input-boolean class="wl-game-task-row"
                                 label="Пройдено автоматически"
                                 (userSelect)="userManipulationsSource.next()"
                                 [control]="viewModel.forceComplete">
    </app-wl-strict-input-boolean>
    <app-strict-push-template-autocomplete class="wl-game-task-row"
                                           textlabel="Push шаблон рассылки о выполнении задания"
                                           [control]="viewModel.template"
                                           (userSelect)="userManipulationsSource.next()"
                                           [isSearch]="true"
    ></app-strict-push-template-autocomplete>
    <app-strict-throttling-autocomplete class="wl-game-task-row"
                                        textLabel="Троттлинг для рассылки"
                                        (userSelect)="userManipulationsSource.next()"
                                        [control]="viewModel.throttling">
    </app-strict-throttling-autocomplete>
    <button class="wl-button-primary wl-game-task-button" [disabled]="!isValid" (click)="save()">{{ saveButtonText }} задание</button>
    <button class="wl-button-primary wl-game-task-button" [disabled]="!canBeCloned" (click)="clone()">Копировать</button>
</div>
