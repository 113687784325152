import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { FilteredOutBetsTargets, FilteredOutBetsTargetsExtensions } from '../../../../../autogen/BattlePass';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FilteredOutBetsTargetsStrategy implements IDropdownStrategy<FilteredOutBetsTargets> {
    getEntities(): Observable<Array<FilteredOutBetsTargets>> {
        const entities = EnumExtensions.toArray(FilteredOutBetsTargets);
        return of(entities);
    }

    map: (model: FilteredOutBetsTargets) => DropdownItem<FilteredOutBetsTargets> = model => {
        const item = new DropdownItem<FilteredOutBetsTargets>();
        item.entity = model;
        item.title = FilteredOutBetsTargetsExtensions.format(model);
        item.id = Number(model);

        return item;
    };
}
